import axios from "axios";
import { SET_ERROR, SET_ERROR_LIGHT } from "./errorReducer";
import { SubmissionError } from "redux-form";
import Swal from "sweetalert2";
import { objToQuery } from "../util/lib";


export const solicitud = {
    BUSCAR_SOLICITUD: "BUSCAR_SOLICITUD",
   
    BUSCAR_SOLICITUD_POR_ID: "BUSCAR_SOLICITUD_POR_ID",
    BUSCAR_SOLICITUD_POR_NUMERO: "BUSCAR_SOLICITUD_POR_NUMERO",
    BUSCAR_SOLICITUD_POR_CODIGO: "BUSCAR_SOLICITUD_POR_CODIGO",
    BUSCAR_SOLICITUD_POR_NOMBRE: "BUSCAR_SOLICITUD_POR_NOMBRE",
    ACTUALIZAR_SOLICITUD: "ACTUALIZAR_SOLICITUD",
    BUSCAR_LISTADO_SOLICITUDES: "BUSCAR_LISTADO_SOLICITUDES",
    GUARDAR_SOLICITUD: "GUARDAR_SOLICITUD",
    ELIMINAR_SOLICITUD: "ELIMINAR_SOLICITUD",
    NUEVA_SOLICITUD: "NUEVA_SOLICITUD",
    BUSCAR_LISTADO_COMPROBANTES: "BUSCAR_LISTADO_COMPROBANTES",
    CARGAR_ARCHIVO_COMPROBANTE: "CARGAR_ARCHIVO_COMPROBANTE",
    INICIALIZAR: "INICIALIZAR",
    
}

const INITIAL_STATE = {
    listaSolicitud: [],
    listaSolicitudesSelected: [],
    solicitudSelected: null,
    solicitud:null,
    listBoletoSolicitud : null,
    cargando: false,
    cantidad: 0,
    currentPage: 0,
    totalPages: 0,
    errores: {}
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case solicitud.BUSCAR_SOLICITUD: {
            
            return {

                ...state,
                listaSolicitud: action.payload.listaSolicitud,
                cargando: true
            };
        }

        case solicitud.BUSCAR_LISTADO_SOLICITUDES: {
          return {
            ...state,
            ...action.payload,
            cargando: true,
          };
        }
        
        case solicitud.BUSCAR_SOLICITUD_POR_ID: {
            return {
                ...state,
                solicitud: action.payload,
                cargando: true
            };
        }
        case solicitud.ELIMINAR_SOLICITUD: {
            const id = action.payload.id;
            return {
                ...state,
                listaSolicitud: state.listaSolicitud.filter(
                    (item) => item.id !== id
                ),
            };
        }
        
        case solicitud.BUSCAR_SOLICITUD_POR_CODIGO: {
            return {
                ...state,
                listaSolicitud: action.payload,
                cargando:true
            }
        }
        case solicitud.BUSCAR_SOLICITUD_POR_NOMBRE: {
            return {
                ...state,
                listaSolicitud: action.payload,
                cargando:true
            }
        }

        case solicitud.BUSCAR_SOLICITUD_POR_NUMERO: {
            return {
                ...state,
                solicitud: action.payload,
                cargando:true
            }
        }
        
        case solicitud.BUSCAR_LISTADO_COMPROBANTES: {
            return {
              ...state,
              ...action.payload,
              cargando: true,
            };
          }

          case solicitud.CARGAR_ARCHIVO_COMPROBANTE: {
            return {
              ...state,
              ...action.payload,
              cargando: true,
            };
          } 
          case solicitud.GUARDAR_SOLICITUD: {
            return {
                ...state,
                solicitud: action.payload.data,
                cargando: true
            };
          } 
          case solicitud.INICIALIZAR: {
            return {
                ...state,
                solicitud:null,
                
            };
          } 
        default:
            return state;
    }
}

const apiUrl = "/services/presupuesto/api/public/transaccion-solicituds";


export const buscarSolicitud = () => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl);
        dispatch({
            type: solicitud.BUSCAR_SOLICITUD,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const buscarSolicitudes = (page, size) => async (dispatch) => {
    try {
      const obj = objToQuery({
        page: page,
        size: size,
      });
      let res = await axios.get(`${apiUrl}${obj}`);
      let cantTotal = parseInt(res.headers["x-total-count"]);
     
      if (res && res.status === 200) {
        dispatch({
          type: solicitud.BUSCAR_SOLICITUD,
          payload: {
            listaSolicitud: res.data,
            currentPage: page,
            totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
          },
        });
      }
    } catch (error) {
      dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    }
  };

  export const buscarSolicitudesConFiltro = (page, size, search) => async (
    dispatch
  ) => {
    try {
      const obj = objToQuery({
        page: page,
        size: size,
        ...search,
      });
      const res = await axios.get(apiUrl + obj);
      let cantTotal = parseInt(res.headers["x-total-count"]);
      if (res && res.status === 200) {
          
        dispatch({
          type: solicitud.BUSCAR_SOLICITUD,
          payload: {
            listaSolicitud: res.data,
            currentPage: page,
            totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
          },
        });
      }
    } catch (error) {
      dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    }
  };

export const buscarSolicitudPorId = (codigo) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/" + codigo)
        dispatch({
            type: solicitud.BUSCAR_SOLICITUD_POR_ID,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const buscarSolicitudPorNumero= (numero) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/Codigo/" + numero)
    
        if(res && res.status === 200){ 
            dispatch({
                type: solicitud.BUSCAR_SOLICITUD_POR_NUMERO,
                payload: res.data
            })
            
        }
        
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        
    }
}





export const generarSolicitud = (solicitud1) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl + "/crear", solicitud1);
        dispatch({
            type: solicitud.BUSCAR_SOLICITUD_POR_NUMERO,
            payload: res.data
        });
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
        throw new SubmissionError(error);
    }
}


export const nuevaSolicitud = () => async (dispatch) => {
    dispatch({ type: solicitud.NUEVA_SOLICITUD });
};

export const inicializarSolicitud = () => async (dispatch) => {
    try {
        dispatch({
            type: solicitud.INICIALIZAR,
            
        })
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    }
}

export const cargarArchivoComprobante1 = (numero,archivo) => async (dispatch) => {
    
    try {
      const res = await axios
        .post("/services/presupuesto/api/public/transaccion-solicituds/informarPago/"+ numero, archivo,
        { 
          headers: {
          'Content-Type': 'multipart/form-data'
          },
          
          onUploadProgress: (progressEvent) => {
          }
        }
      )
        .then((res) => {
          Swal.fire("", res.data , "success");
        });
  
      dispatch({
        type: solicitud.CARGAR_ARCHIVO_COMPROBANTE,
        payload: res,
      });
    } catch (error) {
       
      dispatch({ type: SET_ERROR, payload: error.response });
    }
  };

export const pdf = (codigo) => async (dispatch) => {
    try {
      
        axios(apiUrl + "/generarComprobantePDF/" + codigo, {
        method: 'GET',
        responseType: 'blob' //Force to receive data in a Blob Format
        })
        .then(response => {//Create a Blob from the PDF Stream
            const file = new Blob(
            [response.data],
            {type: 'application/pdf'});//Build a URL from the file
            const fileURL = URL.createObjectURL(file);//Open the URL on new Window
            const pdfWindow = window.open();
             pdfWindow.location.href = fileURL;
        });
    
     
    } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
     
    }
    }

 






