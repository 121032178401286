import axios from 'axios';
import Swal from 'sweetalert2';
import { SubmissionError } from 'redux-form';
import { SET_ERROR_LIGHT, SET_ERROR } from './errorReducer';


export const tiposMapaHidrico = {
  BUSCAR_MAPA_HIDRICO: 'BUSCAR_MAPA_HIDRICO',
  NUEVO_MAPA_HIDRICO: 'NUEVO_MAPA_HIDRICO',
  GUARDAR_MAPA_HIDRICO: 'GUARDAR_MAPA_HIDRICO',
  BUSCAR_MAPA_HIDRICO_POR_ID: 'BUSCAR_MAPA_HIDRICO_POR_ID',
  ACTUALIZAR_MAPA_HIDRICO: 'ACTUALIZAR_MAPA_HIDRICO',
  ELIMINAR_MAPA_HIDRICO: 'ELIMINAR_MAPA_HIDRICO',
  SET_MAPA_HIDRICO: 'SET_MAPA_HIDRICO'

};

const INITIAL_STATE = {
  listaMapaHidrico : [],
  mapaHidrico: {},
  cargando: false,
  errores:{},
  mapaHidricoSelected: ""

};


//Reducer 

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tiposMapaHidrico.BUSCAR_MAPA_HIDRICO: {
      return {
        ...state,
        listaMapaHidrico: action.payload,
        cargando: true
      };
    }
    
    case tiposMapaHidrico.BUSCAR_MAPA_HIDRICO_POR_ID: {
     
      return {
          ...state,
          mapaHidrico: action.payload.data,
          cargando: true
        };
        
    }

    case tiposMapaHidrico.ELIMINAR_MAPA_HIDRICO: {
      const id = action.payload.id;
      return {
        ...state,
        listaMapaHidrico: state.listaMapaHidrico.filter(item => item.id !== id)
      };
    }

    case tiposMapaHidrico.SET_MAPA_HIDRICO: {
      
      return {
        ...state,
        mapaHidrico: action.payload.mapaHidrico,
        mapaHidricoSelected: action.payload,
        };
        
    }
  
    
    default:
      return state;
  }
}

const apiUrl = '/services/presupuesto/api/mapa-hidricos';

//Acciones



export const buscarMapaHidrico = () => async dispatch => {
  const res = await axios.get(apiUrl);
  try{
  if(res && res.status === 200){
      dispatch({ type: tiposMapaHidrico.BUSCAR_MAPA_HIDRICO , payload: res.data });
  }
  }catch(error){
        
    dispatch({ type: SET_ERROR, payload: error.response });
  }  

};

export const setMapaHidrico = mapaHidrico => async dispatch => {
  if(mapaHidrico){
    return dispatch({
      type: tiposMapaHidrico.SET_MAPA_HIDRICO,
      payload: mapaHidrico
    });
  }
  
};


export const buscarMapaHidricoPorId = id => async dispatch => {
    const res = await axios.get(apiUrl + '/' + id);
    dispatch({
      type: tiposMapaHidrico.BUSCAR_MAPA_HIDRICO_POR_ID, payload: res});
};

export const buscarMapaHidricoPorCodigo = id => async dispatch => {
    const res = await axios.get(apiUrl + '/' + id);
    dispatch({
      type: tiposMapaHidrico.BUSCAR_MAPA_HIDRICO_POR_ID, payload: res});
};

export const buscarMapaHidricoPorNombre = query => async dispatch => {
  var isnum = /^\d+$/.test(query);
  let res = null;
  if(isnum){
    res = await axios.get(`${apiUrl}/findByCodigoCauce${query}`);
    dispatch({
      type: tiposMapaHidrico.BUSCAR_MAPA_HIDRICO, payload: res.data
    });
  }else{
    res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase${query}`);
    dispatch({
      type: tiposMapaHidrico.BUSCAR_MAPA_HIDRICO, payload: res.data.content
    });
  }
  
  
};

