import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const tipoLugarPublicacion = {
    BUSCAR_LUGAR_PUBLICACION: 'BUSCAR_LUGAR_PUBLICACION',
    BUSCAR_LUGAR_PUBLICACION_POR_ID: 'BUSCAR_LUGAR_PUBLICACION_POR_ID',
    GUARDAR_LUGAR_PUBLICACION: "GUARDAR_LUGAR_PUBLICACION",
    ACTUALIZAR_LUGAR_PUBLICACION: "ACTUALIZAR_LUGAR_PUBLICACION",
    ELIMINAR_LUGAR_PUBLICACION: "ELIMINAR_LUGAR_PUBLICACION",
    NUEVO_LUGAR_PUBLICACION: "NUEVO_LUGAR_PUBLICACION",
    BUSCAR_LUGAR_PUBLICACION_POR_NOMBRE: "BUSCAR_LUGAR_PUBLICACION_POR_NOMBRE",
    BUSCAR_LUGAR_PUBLICACION_POR_CODIGO: "BUSCAR_LUGAR_PUBLICACION_POR_CODIGO",
    BUSCAR_LUGAR_PUBLICACION_COMBO: "BUSCAR_LUGAR_PUBLICACION_COMBO",
    SET_LUGAR_PUBLICACION: "SET_LUGAR_PUBLICACION",
    SET_DETALLE_LUGAR_PUBLICACION: "SET_DETALLE_LUGAR_PUBLICACION",
    BORRAR_DETALLE_LUGAR_PUBLICACION: "BORRAR_DETALLE_LUGAR_PUBLICACION",
};

const INITIAL_STATE = {
    listaLugarPublicacion: [],
    detalleLugarPublicacionSelected: [],
    lugarPublicacionSelected: null,
    cargando: false,
    errores: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION:
            return {
                ...state,
                listaLugarPublicacion: action.payload,
                cargando: true
            }
        case tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_POR_ID:
            return {
                ...state,
                lugarPublicacionSelected: action.payload,
                cargando: true
            }
        case tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_POR_NOMBRE:
            return {
                ...state,
                listaLugarPublicacion: action.payload,
                cargando: true
            }
        case tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_POR_CODIGO:
            return {
                ...state,
                listaLugarPublicacion: action.payload,
                cargando: true
            }
        case tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_COMBO:
            return {
                ...state,
                listaLugarPublicacion: action.payload,
                cargando: true
            }
        case tipoLugarPublicacion.NUEVO_LUGAR_PUBLICACION:
            return {
                listaLugarPublicacion: [],
                lugarPublicacionSelected: null,
                errores: {},
            }
        case tipoLugarPublicacion.ELIMINAR_LUGAR_PUBLICACION: {
            const id = action.payload.id;
            return {
                ...state,
                listaLugarPublicacion: state.listaLugarPublicacion.filter(
                    (item) => item.id !== id
                ),
            };
        }
        case tipoLugarPublicacion.GUARDAR_LUGAR_PUBLICACION: {
            return {
                ...state,
            };
        }
        case tipoLugarPublicacion.SET_LUGAR_PUBLICACION: {
            return {
                ...state,
                lugarPublicacionSelected: action.payload,
            };
        }
        case tipoLugarPublicacion.SET_DETALLE_LUGAR_PUBLICACION: {
            const dato = action.payload
            return {
                ...state,
                detalleLugarPublicacionSelected: [...state.detalleLugarPublicacionSelected, dato],
                cargando: true
            };
        }
        case tipoLugarPublicacion.BORRAR_DETALLE_LUGAR_PUBLICACION: {
            return {
                ...state,
                detalleLugarPublicacionSelected: state.detalleLugarPublicacionSelected.filter(
                    (item) => item.lugarPublicacion.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/lugar-publicacions";

export const buscarLugarPublicacion = () => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl);
        dispatch({
            type: tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarLugarPublicacionPorId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/" + id);
        dispatch({
            type: tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_POR_ID,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const guardarLugarPublicacion = (lugarPublicacion) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl, lugarPublicacion);
        Swal.fire("", "Datos creados correctamente.", "success");
        dispatch({
            type: tipoLugarPublicacion.GUARDAR_LUGAR_PUBLICACION,
            payload: res,
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
};

export const actualizarLugarPublicacion = (lugarPublicacion) => async (dispatch) => {
    try {
        const res = await axios.put(apiUrl, lugarPublicacion);
        dispatch({
            type: tipoLugarPublicacion.ACTUALIZAR_LUGAR_PUBLICACION,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const eliminarLugarPublicacion = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(apiUrl + "/" + id);
        Swal.fire("", "Se eliminó correctamente.", "success");
        if (res.status === 204) {
            res.id = id;
        }
        dispatch({
            type: tipoLugarPublicacion.ELIMINAR_LUGAR_PUBLICACION,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarLugarPublicacionPorCodigo = (codigo) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByCodigo/${codigo}`);
        dispatch({
            type: tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_POR_CODIGO,
            payload: [res.data]
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarLugarPublicacionPorNombre = (nombre) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${nombre}`);
        dispatch({
            type: tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_POR_NOMBRE,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarLugarPublicacionCombo = (query) => async (dispatch) => {
    let res = null;
    res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${query}`);
    dispatch({
        type: tipoLugarPublicacion.BUSCAR_LUGAR_PUBLICACION_COMBO,
        payload: res.data,
    });
  };

export const nuevoLugarPublicacion = () => async (dispatch) => {
    dispatch({type: tipoLugarPublicacion.NUEVO_LUGAR_PUBLICACION})
};

export const setLugarPublicacion = (lugarPublicacion) => async (dispatch) => {
    if (lugarPublicacion) {
      return dispatch({
        type: tipoLugarPublicacion.SET_LUGAR_PUBLICACION,
        payload: lugarPublicacion,
      });
    }
  };

export const setDetalleLugarPublicacion = (lugarPublicacion, fecha) => async (dispatch) => {
    if (lugarPublicacion && fecha) {
        const detalleLugarPublicacion =
        {
            lugarPublicacion: lugarPublicacion,
            fecha: fecha,
        }
        return dispatch({
            type: tipoLugarPublicacion.SET_DETALLE_LUGAR_PUBLICACION,
            payload: detalleLugarPublicacion,
        });
    }
}

export const deleteDetalleLugarPublicacion = (id) => async (dispatch) => {
    dispatch({
        type: tipoLugarPublicacion.BORRAR_DETALLE_LUGAR_PUBLICACION,
        payload: id,
    });
}

