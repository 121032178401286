import axios from "axios";
import {
//   SET_ERROR,
  SET_ERROR_LIGHT,
//   SET_WARNING_LIGHT , 
//   SET_WARNING
} from "../errorReducer";
// import { SubmissionError } from "redux-form";
// import Swal from "sweetalert2";

export const tipoEstado = {
  BUSCAR_LISTADO_ESTADOS: "BUSCAR_LISTADO_ESTADOS",
  BUSCAR_ESTADO_POR_ID: "BUSCAR_ESTADO_POR_ID",
};

const INITIAL_STATE = {
  listaEstados: [],
  estado: null,
  errores: {},
};

//Reducer

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tipoEstado.BUSCAR_LISTADO_ESTADOS: {
      return {
        ...state,
        listaEstados: action.payload,
      };
    }

    case tipoEstado.BUSCAR_ESTADO_POR_ID: {
      return {
        ...state,
        estado: action.payload.data,
      };
    }
    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/estado-transaccions";

//Acciones

export const buscarListadoEstados = () => async (dispatch) => {
  const res = await axios.get(`${apiUrl}`);
  try {
    if (res && res.status === 200) {
      dispatch({
        type: tipoEstado.BUSCAR_LISTADO_ESTADOS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const buscarEstadoPorId = (id) => async (dispatch) => {
  const res = await axios.get(apiUrl + "/" + id);
  try {
    if (res && res.status === 200) {
      dispatch({
        type: tipoEstado.BUSCAR_ESTADO_POR_ID,
        payload: res,
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};
