import axios from "axios";
import {
  SET_ERROR,
  SET_ERROR_LIGHT,
  // SET_WARNING_LIGHT /* , SET_WARNING */,
} from "../errorReducer";
// import { SubmissionError } from "redux-form";
import Swal from "sweetalert2";
import { objToQuery } from "../../util/lib";

export const tiposTransacciones = {
  BUSCAR_TRANSACCIONES: "BUSCAR_TRANSACCIONES",
  BUSCAR_LISTADO_TRANSACCIONES: "BUSCAR_LISTADO_TRANSACCIONES",
  ACTUALIZAR_TRANSACCION: "ACTUALIZAR_TRANSACCION",
  BUSCAR_TRANSACCION_POR_ID: "BUSCAR_TRANSACCION_POR_ID",
  CERRAR_TRANSFERENCIA: "CERRAR_TRANSFERENCIA",
};

const INITIAL_STATE = {
  listaTransacciones: [],
  listaTransaccionesSelected: [],
  transaccion: null,
  errores: {},
  cargando: false,
  cantidad: 0,
  currentPage: 0,
  totalPages: 0,
};

//Reducer

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tiposTransacciones.BUSCAR_TRANSACCIONES: {
      return {
        ...state,
        ...action.payload,
        cargando: true,
      };
    }

    case tiposTransacciones.BUSCAR_LISTADO_TRANSACCIONES: {
      return {
        ...state,
        ...action.payload,
        cargando: true,
      };
    }
    case tiposTransacciones.BUSCAR_TRANSACCION_POR_ID: {
      return {
        ...state,
        transaccion: action.payload.data,
      };
    }

    case tiposTransacciones.ACTUALIZAR_TRANSACCION: {
      return {
        ...state,
        transaccion: action.payload.data,
      };
    }

    case tiposTransacciones.CERRAR_TRANSFERENCIA: {
      return {
        ...state,
        transaccion: action.payload.data,
      };
    }
    
    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/transferencias";

//Acciones

export const buscarTransacciones = (page, size) => async (dispatch) => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
    });
    let res = await axios.get(`${apiUrl}${obj}`);
    let cantTotal = parseInt(res.headers["x-total-count"]);
    if (res && res.status === 200) {
      dispatch({
        type: tiposTransacciones.BUSCAR_TRANSACCIONES,
        payload: {
          listaTransacciones: res.data,
          currentPage: page,
          totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
        },
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const buscarTransaccionesConFiltro = (page, size, search) => async (
  dispatch
) => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
      ...search,
    });
    const res = await axios.get(apiUrl + obj);
    let cantTotal = parseInt(res.headers["x-total-count"]);
    if (res && res.status === 200) {
      dispatch({
        type: tiposTransacciones.BUSCAR_LISTADO_TRANSACCIONES,
        payload: {
          listaTransacciones: res.data,
          currentPage: page,
          totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
        },
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const actualizarTransaccion = (transaccion) => async (dispatch) => {
  try {
    const res = await axios.put(apiUrl, transaccion);

    Swal.fire("", "Se cerró correctamente.", "success");
    return dispatch({
      type: tiposTransacciones.ACTUALIZAR_TRANSACCION,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const buscarTransaccionPorId = (id) => async (dispatch) => {
  const res = await axios.get(`${apiUrl}/${id}`);
  dispatch({
    type: tiposTransacciones.BUSCAR_TRANSACCION_POR_ID,
    payload: res,
  });
};

export const conciliarTransaccion = (transaccion, boletos) => async (
  dispatch
) => {
  try {
    transaccion.boletos = boletos;

    const res = await axios.put(apiUrl + "/updateConciliado", transaccion);
    if (res && res.status === 200) {
      Swal.fire("", "Se Concilió Correctamente!", "success");
    }
    return dispatch({
      type: tiposTransacciones.ACTUALIZAR_TRANSACCION,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const guardarTransaccionPendiente = (transaccion, boletos) => async (
  dispatch
) => {
  transaccion.boletos = boletos;

  try {
    const res = await axios.put(apiUrl + "/updatePendiente", transaccion);
    if (res && res.status === 200) {
      Swal.fire("", "Datos Guardados Correctamente!", "success");
    }
    return dispatch({
      type: tiposTransacciones.ACTUALIZAR_TRANSACCION,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const cerrarTransaccion = (transaccion) => async (dispatch) => {
  try {
    const res = await axios.put(apiUrl + '/cerrarTransferencia', transaccion);

    Swal.fire("", "Pago Realizado Correctamente!", "success");
    return dispatch({
      type: tiposTransacciones.CERRAR_TRANSFERENCIA,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};