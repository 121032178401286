import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const TemaExpediente = {
    BUSCAR_TEMA_EXPEDIENTE: 'BUSCAR_TEMA_EXPEDIENTE',
    SET_TEMA_EXPEDIENTE: 'SET_TEMA_EXPEDIENTE',
    BUSCAR_TEMA_EXPEDIENTE_COMBO: `BUSCAR_TEMA_EXPEDIENTE_COMBO`
};

const INITIAL_STATE = {
    TemaExpediente: {},
    TemaExpedienteSelected: "",
    listaTemaExpediente: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case TemaExpediente.BUSCAR_TEMA_EXPEDIENTE: {
            return {
                ...state,
                listaTemaExpediente: action.payload,
                cargando: true
            };
        }

        case TemaExpediente.SET_TEMA_EXPEDIENTE: {
            return {
                ...state,
                TemaExpediente: action.payload.organismo,
                TemaExpediente: action.payload,
            };
        }

        case TemaExpediente.BUSCAR_TEMA_EXPEDIENTE_COMBO: {
            return {
                ...state,
                listaTemaExpediente: action.payload,
                cargando: true,
            };
        }

        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/temas";

export const buscarTemaExpedientesPorNombre = (query) => async (dispatch) => {
    var isnum = /^\d+$/.test(query);
    let res = null;
    if (isnum) {
        res = await axios.get(`${apiUrl}/findByCodigo/${query}`);
        dispatch({
            type: TemaExpediente.BUSCAR_TEMA_EXPEDIENTE,
            payload: [res.data],
        });
    } else {
        if (query.length > 2) {
            res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${query}`);
            dispatch({
                type: TemaExpediente.BUSCAR_TEMA_EXPEDIENTE_COMBO,
                payload: res.data,
            });
        }
    }
};

export const buscarTemaExpedientes = () => async (dispatch) => {
    try {

        const res = await axios.get(apiUrl);

        return dispatch({
            type: TemaExpediente.BUSCAR_TEMA_EXPEDIENTE,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const setTemaExpediente = (Expediente) => async (dispatch) => {
    if (Expediente) {
        return dispatch({
            type: TemaExpediente.SET_TEMA_EXPEDIENTE,
            payload: Expediente,
        });
    }
};

