import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import {objToQuery} from '../../util/lib';

export const ACTION_TYPES = {
    BUSCAR_LICITACION: 'BUSCAR_LICITACION',
    BUSCAR_LICITACION_POR_ID: 'BUSCAR_LICITACION_POR_ID',
    GUARDAR_LICITACION: "GUARDAR_LICITACION",
    ACTUALIZAR_LICITACION: "ACTUALIZAR_LICITACION",
    ELIMINAR_LICITACION: "ELIMINAR_LICITACION",
    NUEVO_LICITACION: "NUEVO_LICITACION",
    SUBIR_ARCHIVO: "SUBIR_ARCHIVO",
    ELIMINAR_ARCHIVO: "ELIMINAR_ARCHIVO",
    BUSCAR_COMBO_LICITACION: "BUSCAR_COMBO_LICITACION",
    BUSCAR_LICITACION_SEARCH: "BUSCAR_LICITACION_SEARCH",
    SET_LICITACION: "SET_LICITACION",
    SET_LICITACION_SELECTED: "SET_LICITACION_SELECTED",
    CLEAN_LICITACION_SELECTED: "CLEAN_LICITACION_SELECTED",
    BUSCAR_LICITACION_POR_CODIGO_UNICO: "BUSCAR_LICITACION_POR_CODIGO_UNICO",
};

const INITIAL_STATE = {
    listaLicitacion: [],
    listaLicitacionSearch: [],
    listaArchivosLicitacion: [],
    licitacionSelected: null,
    licitacionSelected2: null,
    licitacionCreated: null,
    cargando: false,
    errores: {},
    currentPage: 0,
    totalPages: 0,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACTION_TYPES.BUSCAR_LICITACION:
            return {
              ...state,
              ...action.payload,
              cargando: true,
            };
        case ACTION_TYPES.BUSCAR_LICITACION_POR_ID:
            return {
                ...state,
                licitacionSelected: action.payload,
                listaArchivosLicitacion: action.payload.archivoLicitacions,
                cargando: true
            }
        case ACTION_TYPES.NUEVO_LICITACION:
            return {
                listaLicitacion: [],
                listaLicitacionSearch: [],
                listaArchivosLicitacion: [],
                licitacionSelected: null,
                licitacionCreated: null,
                errores: {},
            }
        case ACTION_TYPES.ELIMINAR_LICITACION: {
            const id = action.payload.id;
            return {
                ...state,
                listaLicitacion: state.listaLicitacion.filter(
                    (item) => item.id !== id
                ),
            };
        }
        case ACTION_TYPES.GUARDAR_LICITACION: {
            return {
                ...state,
                licitacionCreated: action.payload
            };
        }
        case ACTION_TYPES.SUBIR_ARCHIVO: {
            return {
                ...state,
                listaArchivosLicitacion: action.payload
            };
        }
        case ACTION_TYPES.ELIMINAR_ARCHIVO: {
            return {
                ...state,
                listaArchivosLicitacion: state.listaArchivosLicitacion.filter(
                    (item) => item.id !== action.payload
                ),
            };
        }
        
        case ACTION_TYPES.BUSCAR_LICITACION_SEARCH:
            return {
                ...state,
                listaLicitacionSearch: action.payload,
                cargando: true
            }
        case ACTION_TYPES.BUSCAR_COMBO_LICITACION: {
            return {
                ...state,
                listaLicitacionSearch: action.payload,
                cargando: true
            };
        }
        case ACTION_TYPES.SET_LICITACION: {
            return {
                ...state,
                licitacionSelected: action.payload,
            };
        }
        case ACTION_TYPES.SET_LICITACION_SELECTED: {
            return {
                ...state,
                licitacionSelected2: action.payload,
            };
        }
        case ACTION_TYPES.CLEAN_LICITACION_SELECTED: {
            return {
                ...state,
                licitacionSelected2: null,
                listaLicitacionSearch: [],
            };
        }
        case ACTION_TYPES.BUSCAR_LICITACION_POR_CODIGO_UNICO: {
            return {
                ...state,
                licitacionSelected2: action.payload,
            };
        }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/public/licitacions";

export const buscarLicitacion = (page, size, search) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        ...search,
        sort: "fechaPublicacion,desc"
      });  
    try {
        const res = await axios.get(apiUrl + obj);
        let cantTotal = parseInt(res.headers["x-total-count"]);
        dispatch({
            type: ACTION_TYPES.BUSCAR_LICITACION,
            payload: {
                listaLicitacion: res.data,
                currentPage: page,
                totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
            }
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarLicitacionPorId = (id) => async (dispatch) => {
    try {
        const searchProps = {"id.equals": id ? id : null};
        const obj = objToQuery({
            ...searchProps,
          });  
        const res = await axios.get(apiUrl + obj);
        dispatch({
            type: ACTION_TYPES.BUSCAR_LICITACION_POR_ID,
            payload: res.data[0]
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const guardarLicitacion = (licitacion) => async (dispatch) => {
    try {
        console.log(licitacion);
        const res = await axios.post(apiUrl, licitacion);
        console.log(res)
        Swal.fire("", "Datos creados correctamente.", "success");
        const response = await axios.get(apiUrl + "/" + res.data.id);
        dispatch({
            type: ACTION_TYPES.GUARDAR_LICITACION,
            payload: response.data,
        });
    } catch (error) {
        console.log(error)
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
};

export const actualizarLicitacion = (licitacion) => async (dispatch) => {
    try {
        const res = await axios.put(apiUrl, licitacion);
        Swal.fire("", "Datos actualizados correctamente.", "success");
        dispatch({
            type: ACTION_TYPES.ACTUALIZAR_LICITACION,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const eliminarLicitacion = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(apiUrl + "/" + id);
        Swal.fire("", "Se eliminó correctamente.", "success");
        if (res.status === 204) {
            res.id = id;
        }
        dispatch({
            type: ACTION_TYPES.ELIMINAR_LICITACION,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const nuevaLicitacion = () => async (dispatch) => {
    dispatch({type: ACTION_TYPES.NUEVO_LICITACION})
};

export const subirArchivo = (idLicitacion, archivoLicitacion) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl + "/agregarArchivo/" + idLicitacion, archivoLicitacion);
        Swal.fire("", res.data, "success");
        const res2 = await axios.get(apiUrl + "/" + idLicitacion);
        dispatch({ type: ACTION_TYPES.SUBIR_ARCHIVO, payload: res2.data.archivoLicitacion });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
}

export const eliminarArchivo = (idLicitacion, idArchivoLicitacion) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl + "/quitarArchivo/"+ idArchivoLicitacion + "/" + idLicitacion,);
        Swal.fire("", res.data, "success");
        dispatch({ type: ACTION_TYPES.ELIMINAR_ARCHIVO, payload: idArchivoLicitacion });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
}

export const buscarLicitacionSearch = (codigoUnico) => async (dispatch) => {
    var obj = objToQuery({
        "codigoUnico.equalsActive": codigoUnico? true : false,
        "codigoUnico.contains": codigoUnico,
    });
    try {
        const res = await axios.get(apiUrl + obj);
        dispatch({
            type: ACTION_TYPES.BUSCAR_LICITACION_SEARCH,
            payload: res.data
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarLicitacionCombo = (query) => async (dispatch) => {
    var obj;
    var isnum = /^\d+$/.test(query);
    if (isnum) {
        obj = objToQuery({
            "numero.equals": query,
        });
    } else {
        obj = objToQuery({
            "codigoUnico.contains": query,
        }); 
    }
    try {
        const res = await axios.get(apiUrl + obj);
        dispatch({
            type: ACTION_TYPES.BUSCAR_COMBO_LICITACION,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const setLicitacion = (licitacion) => async (dispatch) => {
    if (licitacion) {
      return dispatch({
        type: ACTION_TYPES.SET_LICITACION,
        payload: licitacion,
      });
    }
  };

export const setLicitacionSelected = (licitacion) => async (dispatch) => {
    if (licitacion) {
      return dispatch({
        type: ACTION_TYPES.SET_LICITACION_SELECTED,
        payload: licitacion,
      });
    }
  };

export const cleanLicitacionSelected = () => async (dispatch) => {
    return dispatch({
      type: ACTION_TYPES.CLEAN_LICITACION_SELECTED,
    });
  };