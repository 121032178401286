import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";
import {objToQuery} from '../../util/lib';

export const tipoNormativa = {
    BUSCAR_NORMATIVA: 'BUSCAR_NORMATIVA',
    BUSCAR_NORMATIVA_POR_ID: 'BUSCAR_NORMATIVA_POR_ID',
    GUARDAR_NORMATIVA: "GUARDAR_NORMATIVA",
    ACTUALIZAR_NORMATIVA: "ACTUALIZAR_NORMATIVA",
    ELIMINAR_NORMATIVA: "ELIMINAR_NORMATIVA",
    NUEVO_NORMATIVA: "NUEVO_NORMATIVA",
    SUBIR_ARCHIVO: "SUBIR_ARCHIVO",
    ELIMINAR_ARCHIVO: "ELIMINAR_ARCHIVO",
    BUSCAR_COMBO_NORMATIVA: "BUSCAR_COMBO_NORMATIVA",
    BUSCAR_NORMATIVA_SEARCH: "BUSCAR_NORMATIVA_SEARCH",
    SET_NORMATIVA: "SET_NORMATIVA",
    SET_NORMATIVA_SELECTED: "SET_NORMATIVA_SELECTED",
    CLEAN_NORMATIVA_SELECTED: "CLEAN_NORMATIVA_SELECTED",
    BUSCAR_NORMATIVA_POR_CODIGO_UNICO: "BUSCAR_NORMATIVA_POR_CODIGO_UNICO",
};

const INITIAL_STATE = {
    listaNormativa: [],
    listaNormativaSearch: [],
    listaArchivosNormativa: [],
    normativaSelected: null,
    normativaSelected2: null,
    normativaCreated: null,
    cargando: false,
    errores: {},
    currentPage: 0,
    totalPages: 0,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case tipoNormativa.BUSCAR_NORMATIVA:
            return {
              ...state,
              ...action.payload,
              cargando: true,
            };
        case tipoNormativa.BUSCAR_NORMATIVA_POR_ID:
            return {
                ...state,
                normativaSelected: action.payload,
                listaArchivosNormativa: action.payload.archivoNormativas,
                cargando: true
        }



        case tipoNormativa.BUSCAR_NORMATIVA_SEARCH:
            return {
                ...state,
                listaNormativaSearch: action.payload,
                cargando: true
            }
        case tipoNormativa.BUSCAR_COMBO_NORMATIVA: {
            return {
                ...state,
                listaNormativaSearch: action.payload,
                cargando: true
            };
        }
        case tipoNormativa.SET_NORMATIVA: {
            return {
                ...state,
                normativaSelected: action.payload,
            };
        }
        case tipoNormativa.SET_NORMATIVA_SELECTED: {
            return {
                ...state,
                normativaSelected2: action.payload,
            };
        }
        case tipoNormativa.CLEAN_NORMATIVA_SELECTED: {
            return {
                ...state,
                normativaSelected2: null,
                listaNormativaSearch: [],
            };
        }

        
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/public/normativas/publico";

export const buscarNormativa = (page, size, search) => async (dispatch) => {
    const obj = objToQuery({
        page: page,
        size: size,
        sort: "anio,desc&sort=numero,desc",
        
        ...search,
      });  
    try {
        const res = await axios.get(apiUrl + obj );
        let cantTotal = parseInt(res.headers["x-total-count"]);
        dispatch({
            type: tipoNormativa.BUSCAR_NORMATIVA,
            payload: {
                listaNormativa: res.data,
                currentPage: page,
                totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
            }
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarNormativaPorId = (id) => async (dispatch) => {
    try {
        const searchProps = {
            "id.equals": id ? id : null
            
          };

        const obj = objToQuery({
            ...searchProps,
          });  
        const res = await axios.get(apiUrl + obj);

        dispatch({
            type: tipoNormativa.BUSCAR_NORMATIVA_POR_ID,
            payload: res.data[0]
        })
    } catch (error) {
        console.log("NO ES ESTE ERROR " + error)
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};


export const buscarNormativaSearch = (codigoUnico) => async (dispatch) => {
    var obj = objToQuery({
        "codigoUnico.equalsActive": codigoUnico? true : false,
        "codigoUnico.contains": codigoUnico,
    });
    try {
        const res = await axios.get(apiUrl + obj);
        dispatch({
            type: tipoNormativa.BUSCAR_NORMATIVA_SEARCH,
            payload: res.data
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarNormativaCombo = (query) => async (dispatch) => {
    var obj;
    var isnum = /^\d+$/.test(query);
    if (isnum) {
        obj = objToQuery({
            "numero.equals": query,
        });
    } else {
        obj = objToQuery({
            "codigoUnico.contains": query,
        }); 
    }
    try {
        const res = await axios.get(apiUrl + obj);
        dispatch({
            type: tipoNormativa.BUSCAR_COMBO_NORMATIVA,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const setNormativa = (normativa) => async (dispatch) => {
    if (normativa) {
      return dispatch({
        type: tipoNormativa.SET_NORMATIVA,
        payload: normativa,
      });
    }
  };

export const setNormativaSelected = (normativa) => async (dispatch) => {
    if (normativa) {
      return dispatch({
        type: tipoNormativa.SET_NORMATIVA_SELECTED,
        payload: normativa,
      });
    }
  };

export const cleanNormativaSelected = () => async (dispatch) => {
    return dispatch({
      type: tipoNormativa.CLEAN_NORMATIVA_SELECTED,
    });
  };