import axios from "axios";
import { SubmissionError } from "redux-form";
import { SET_ERROR_LIGHT, SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { objToQuery } from "../util/lib";

export const ACTION_TYPES = {
  BUSCAR_LISTADO_PRORRATA: "prorrata/BUSCAR_LISTADO_PRORRATA",
  NUEVO_PRORRATA: "prorrata/NUEVO_PRORRATA",
  GUARDAR_PRORRATA: "prorrata/GUARDAR_PRORRATA",
  BUSCAR_PRORRATA_POR_ID: "prorrata/BUSCAR_PRORRATA_POR_ID",
  ACTUALIZAR_PRORRATA: "prorrata/ACTUALIZAR_PRORRATA",
  ELIMINAR_PRORRATA: "prorrata/ELIMINAR_PRORRATA",
  GUARDAR_RANGO: "prorrata/GUARDAR_RANGO",
  ACTUALIZAR_CARGA: "prorrata/ACTUALIZAR_CARGA"
};

const initialState = {
  lstProrratas: [],
  prorrata: null,
  errores: {},
  currentPage: 0,
  totalPages: 0,
  cargando: false,
  lstRangos: [],
  anioCarga: new Date().getFullYear(),
  tipoServicioCarga: 1,
  tipoProrrataCarga: 1,
  lstActual: []
};

// Reducer

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.BUSCAR_LISTADO_PRORRATA: {
      return {
        ...state,
        ...action.payload,
        cargando: true,
      };
    }

    case ACTION_TYPES.ELIMINAR_PRORRATA: {
      const id = action.payload;
      return {
        ...state,
        lstProrratas: state.lstProrratas.filter((item) => item.id !== id),
        lstActual: state.lstActual.filter((item) => item.id !== id),
      };
    }

    case ACTION_TYPES.BUSCAR_PRORRATA_POR_ID: {
      return {
        ...state,
        prorrata: action.payload.data,
      };
    }

    case ACTION_TYPES.NUEVO_PRORRATA: {
      return {
        ...state,
        prorrata: {
          tipoServicio: null,
          organismo: null,
          tarifa: null,
          //fechaDesde: new Date(),
          //fechaHasta: null,
          prorrata: null,
          importe: 0.0,
          anio: state.anioCarga,
        },
      };
    }

    case ACTION_TYPES.GUARDAR_PRORRATA: {
      return {
        ...state,
        prorrata: action.payload.data,
        lstActual: state.lstActual.concat(action.payload.data)
      }
    }

    case ACTION_TYPES.ACTUALIZAR_PRORRATA: {
      return {
        ...state,
        lstActual: state.lstActual.concat(action.payload.data)
      }
    }

    case ACTION_TYPES.GUARDAR_RANGO: {
      return {
        ...state,
        lstRangos: action.payload.data,
        lstActual: state.lstActual.concat(action.payload.data),
      }
    }

    case ACTION_TYPES.ACTUALIZAR_CARGA: {
      return {
        ...state,
        ...action.payload
      };
    }

    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/prorratas";

// Actions

export const buscarProrratas = (page, size, search) => async (dispatch) => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
      ...search,
    });
    const res = await axios.get(apiUrl + obj);
    let cantTotal = parseInt(res.headers["x-total-count"]);
    if (res && res.status === 200) {
      dispatch({
        type: ACTION_TYPES.BUSCAR_LISTADO_PRORRATA,
        payload: {
          lstProrratas: res.data,
          currentPage: page,
          totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
        },
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const buscarProrratasFull = (prorrata) => async (dispatch) => {
  try {
    var res = await axios.get(`${apiUrl}`);
    dispatch({ type: ACTION_TYPES.BUSCAR_LISTADO_PRORRATA, payload: res }); //, payload: res });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const nuevoProrrata = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.NUEVO_PRORRATA });
};

export const guardarProrrata = (prorrata) => async (dispatch) => {
  try {
    /*if (prorrata.fechaDesde === undefined) {
      prorrata = {
        ...prorrata,
        fechaDesde: new Date(),
      };
    }*/

    const res = await axios.post(apiUrl, prorrata);
    dispatch({ type: ACTION_TYPES.GUARDAR_PRORRATA, payload: res });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};

export const buscarProrrataPorId = (id) => async (dispatch) => {
  const res = await axios.get(`${apiUrl}/${id}`);
  dispatch({ type: ACTION_TYPES.BUSCAR_PRORRATA_POR_ID, payload: res });
};

export const actualizarProrrata = (prorrata) => async (dispatch) => {
  try {
    const res = await axios.put(apiUrl, prorrata);
    return dispatch({
      type: ACTION_TYPES.ACTUALIZAR_PRORRATA,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};

export const eliminarProrrata = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`${apiUrl}/${id}`);

    Swal.fire("", "Se eliminó correctamente.", "success");
    dispatch({
      type: ACTION_TYPES.ELIMINAR_PRORRATA,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const buscarProrratasPorCauce = (
  anio,
  mapaHidrico,
  tipoServicio
) => async (dispatch) => {
  try {
    const tipoServ = tipoServicio ? tipoServicio.id : 1;
    var res = await axios.get(
      `${apiUrl}/prorrataPorCauce/${anio}/${mapaHidrico.id}/${tipoServ}`
    );

    dispatch({
      type: ACTION_TYPES.BUSCAR_LISTADO_PRORRATA,
      payload: { lstProrratas: res.data },
    }); //, payload: res });
  } catch (error) {
    throw dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const guardarRango = (mapaHidricoDesde, mapaHidricoHasta, prorrata) => async (dispatch) => {
  try{
    const res = await axios.post(`${apiUrl}/rangoCauce/${mapaHidricoDesde}/${mapaHidricoHasta}`, prorrata);
    dispatch({ type: ACTION_TYPES.GUARDAR_RANGO, payload: res });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};

//Se actualizan los valores de anioCarga, tipoServicioCarga y tipoProrrataCarga para inicializar los formularios en esos valores
export const actualizarTipoServicio = (tipoServicio) => async (dispatch) => {
  
  dispatch({ type: ACTION_TYPES.ACTUALIZAR_CARGA, payload: {tipoServicioCarga: tipoServicio} });
};

export const actualizarTipoProrrata = (tipoProrrata) => async (dispatch) => {
  
  dispatch({ type: ACTION_TYPES.ACTUALIZAR_CARGA, payload: {tipoProrrataCarga: tipoProrrata} });
};

export const actualizarAnio = (anio) => async (dispatch) => {
  
  dispatch({ type: ACTION_TYPES.ACTUALIZAR_CARGA, payload: {anioCarga: anio} });
};