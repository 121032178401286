import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';
import { BrowserRouter, HashRouter, Route, Switch } from 'react-router-dom';

// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Cargando...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

const PublicLayout = React.lazy(() => import('./containers/PublicLayout'));

const App = () => {

  return <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/" name="Departamento General de Irrigación" render={props => <PublicLayout {...props} />} />

            </Switch>
          </React.Suspense>
        </BrowserRouter>      
    
}

/*class App extends Component {

  
  
  render() {
    


    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/public" name="Departamento General de Irrigación" render={props => <PublicLayout {...props}/>} />
              
              <Route path="/" name="Inicio" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>      
    );
  }
}*/

export default App;
