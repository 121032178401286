import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const Organismo = {
    BUSCAR_ORGANISMO_POR_PADRE: 'BUSCAR_ORGANISMO_POR_PADRE',
};

const INITIAL_STATE = {
    listaOrganismo: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case Organismo.BUSCAR_ORGANISMO_POR_PADRE:
            return {
                ...state,
                listaOrganismo: action.payload,
                cargando: true
            }

        default:
            return state;
    }
}

const apiUrl = "/services/presupuesto/api/organismos";

export const buscarOrganismosPorPadre = (id) => async (dispatch) => {
    try {

        id = id === "" ? 0 : id

        const aux = [];

        const res = await axios.get(apiUrl + "/findOrganismosPorPadre/" + id);

        res.data.forEach(element => {
            aux.push({
                ...element,
                name: `${element.codigo} - ${element.nombre}`,
                parentId: id == 0 ? "" : id,
            })
        });

        return dispatch({
            type: Organismo.BUSCAR_ORGANISMO_POR_PADRE,
            payload: aux
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

