import axios from "axios";
import { SET_ERROR } from "../errorReducer";

export const archivoNormativa = {
    VER_ARCHIVO_NORMATIVA: 'VER_ARCHIVO_NORMATIVA',
};

const INITIAL_STATE = {
    archivoSelected: null,
    archivoDescargaSelected: null,
    cargando: false,
    errores: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case archivoNormativa.VER_ARCHIVO_NORMATIVA:
            
            return {
                ...state,
                archivoDescargaSelected: action.payload,
                cargando: true
            }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/public/archivo-normativas";

export const verArchivoNormativa = (id) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/bajaArchivo/" + id, {responseType: 'blob'});
        const file = new Blob(
            [res.data],
            {type: 'application/pdf'});
        return file;
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const descargarArchivoNormativa = (id, nombreArchivo) => async (dispatch) => {
    try {
        axios.get(apiUrl + "/bajaArchivo/" + id, {responseType: 'blob'})
                .then(response => {//Create a Blob from the PDF Stream
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                const file = new Blob(
                [response.data],
                {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                a.href = fileURL;
                a.download = nombreArchivo;
                a.click();
                window.URL.revokeObjectURL(fileURL);
            });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};