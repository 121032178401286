import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const Reparticion = {
    BUSCAR_REPARTICION: 'BUSCAR_REPARTICION',
    SET_REPARTICION: 'SET_REPARTICION',
    BUSCAR_REPARTICION_COMBO: `BUSCAR_REPARTICION_COMBO`
};

const INITIAL_STATE = {
    reparticion: {},
    reparticionSelected: "",
    listaReparticion: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case Reparticion.BUSCAR_REPARTICION: {
            return {
                ...state,
                listaReparticion: action.payload,
                cargando: true
            };
        }

        case Reparticion.SET_REPARTICION: {
            return {
                ...state,
                reparticion: action.payload.organismo,
                reparticion: action.payload,
            };
        }

        case Reparticion.BUSCAR_REPARTICION_COMBO: {
            return {
                ...state,
                listaReparticion: action.payload,
                cargando: true,
            };
        }

        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/reparticions";
const apiPublicUrl = "/services/expedientes/api/public/reparticions";

export const buscarReparticionesPorNombre = (query) => async (dispatch) => {
    var isnum = /^\d+$/.test(query);
    let res = null;
    if (isnum) {
        res = await axios.get(`${apiUrl}/findByCodigo/${query}`);
        dispatch({
            type: Reparticion.BUSCAR_REPARTICION,
            payload: [res.data],
        });
    } else {
        if (query.length > 2) {
            res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${query}`);
            dispatch({
                type: Reparticion.BUSCAR_REPARTICION_COMBO,
                payload: res.data,
            });
        }
    }
};

export const buscarReparticiones = () => async (dispatch) => {
    try {

        const res = await axios.get(apiPublicUrl+"/subdelegacion");

        return dispatch({
            type: Reparticion.BUSCAR_REPARTICION,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const setReparticion = (reparticion) => async (dispatch) => {
    if (reparticion) {
        return dispatch({
            type: Reparticion.SET_REPARTICION,
            payload: reparticion,
        });
    }
};

