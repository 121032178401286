import axios from "axios";
import { SET_ERROR, SET_ERROR_LIGHT } from "../errorReducer";
// import Swal from "sweetalert2";
import { objToQuery } from "../../util/lib";

export const tiposTipoTransacciones = {
  BUSCAR_LISTADO_TIPO_TRANSACCIONES: "BUSCAR_LISTADO_TIPO_TRANSACCIONES",
  SET_TIPO_TRANSACCION: "SET_TIPO_TRANSACCION",
};

const INITIAL_STATE = {
  listaTipoTransacciones: [],
  listaTipoTransaccionesSelected: [],
  tipoTransaccion: null,
  errores: {},
  cargando: false,
  cantidad: 0,
  currentPage: 0,
  totalPages: 0,
  tipoTransaccion: null,
  tipoTransaccionSelected: null,
};

//Reducer

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tiposTipoTransacciones.BUSCAR_LISTADO_TIPO_TRANSACCIONES: {
      return {
        ...state,
        ...action.payload,
        cargando: true,
      };
    }

    case tiposTipoTransacciones.SET_TIPO_TRANSACCION: {
      return {
        ...state,
        tipoTransaccion: action.payload.data,
      };
    }

    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/tipo-transaccion-bancarias";

//Acciones

export const buscarTipoTransacciones = (page, size, search) => async (
  dispatch
) => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
      ...search,
    });
    const res = await axios.get(apiUrl + obj);
    let cantTotal = parseInt(res.headers["x-total-count"]);
    if (res && res.status === 200) {
      dispatch({
        type: tiposTipoTransacciones.BUSCAR_LISTADO_TIPO_TRANSACCIONES,
        payload: {
          listaTipoTransacciones: res.data,
          currentPage: page,
          totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
        },
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const setTipoTransaccion = (tipoTransaccion) => (dispatch) => {
  return dispatch({
    type: tiposTipoTransacciones.SET_TIPO_TRANSACCION,
    payload: tipoTransaccion,
  });
};
