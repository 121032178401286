import axios from 'axios';
import Swal from 'sweetalert2';
import { SubmissionError } from 'redux-form';
import { SET_ERROR_LIGHT, SET_ERROR } from './errorReducer';


export const tiposPersona = {
  BUSCAR_PERSONA: 'BUSCAR_PERSONA',
  BUSCAR_PERSONA_POR_ID: 'BUSCAR_PERSONA_POR_ID',
  BUSCAR_PERSONA_POR_CUIT: 'BUSCAR_PERSONA_POR_CUIT',
  BUSCAR_PERSONA_POR_CODIGO: 'BUSCAR_PERSONA_POR_CODIGO',
  SET_PERSONA: 'SET_PERSONA'

};

const INITIAL_STATE = {
  listaPersona : [],
  persona: {},
  cargando: false,
  errores:{},
  personaSelected: ""

};


//Reducer 

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tiposPersona.BUSCAR_PERSONA: {
      return {
        ...state,
        listaPersona: action.payload,
        cargando: true
      };
    }
    
    case tiposPersona.BUSCAR_PERSONA_POR_ID: {
     
      return {
          ...state,
          Persona: action.payload.data,
          cargando: true
        };
        
    }

    case tiposPersona.BUSCAR_PERSONA_POR_CUIT: {
       return {
           ...state,
           Persona: action.payload.data,
           cargando: true
         };
         
     }

    case tiposPersona.SET_PERSONA: {
      
      return {
        ...state,
        persona: action.payload.persona,
        personaSelected: action.payload,
        };
        
    }

    case tiposPersona.BUSCAR_PERSONA_POR_CODIGO: {
      
      
      return {
          ...state,
          uso: action.payload,
          cargando: true
        };
        
    }
  
    
    default:
      return state;
  }
}

const apiUrl = '/services/presupuesto/api/personas';

//Acciones



export const buscarPersona = () => async dispatch => {
  const res = await axios.get(apiUrl);
  try{
  if(res && res.status === 200){
      dispatch({ type: tiposPersona.BUSCAR_PERSONA , payload: res.data });
  }
  }catch(error){
        
    dispatch({ type: SET_ERROR, payload: error.response });
  }  

};

export const setPersona = persona => async dispatch => {
  if(persona){
    return dispatch({
      type: tiposPersona.SET_PERSONA,
      payload: persona
    });
  }
  
};


export const buscarPersonaPorId = id => async dispatch => {
    const res = await axios.get(apiUrl + '/' + id);
    dispatch({
      type: tiposPersona.BUSCAR_PERSONA_POR_ID, payload: res});
};

export const buscarPersonaPorCodigo = codigo => async dispatch => {
    const res = await axios.get(`${apiUrl}/findByCodigo/${codigo}`);
    dispatch({
      type: tiposPersona.BUSCAR_PERSONA_POR_ID, payload: res});
};

export const buscarPersonaPorCuit = cuit => async dispatch => {
  const res = await axios.get(`${apiUrl}/findByCuit/${cuit}`);
  dispatch({
    type: tiposPersona.BUSCAR_PERSONA_POR_CUIT, payload: res});
};

export const buscarPersonaPorNombre = query => async dispatch => {
  var isnum = /^\d+$/.test(query);
  let res = null;
  if(isnum){
    res = await axios.get(`${apiUrl}/findByNombre/${query}`);
    dispatch({
      type: tiposPersona.BUSCAR_PERSONA, payload: res.data
    });
  }else{
    res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${query}`);
    dispatch({
      type: tiposPersona.BUSCAR_PERSONA, payload: res.data.content
    });
  }
  
  
};

