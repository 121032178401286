import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const ACTION_TYPES = {
    BUSCAR_TIPO_LICITACION: "BUSCAR_TIPO_LICITACION",
    BUSCAR_TIPO_LICITACION_POR_NOMBRE: "BUSCAR_TIPO_LICITACION_POR_NOMBRE",
    BUSCAR_TIPO_LICITACION_POR_ID: "BUSCAR_TIPO_LICITACION_POR_ID",
    GUARDAR_TIPO_LICITACION: "GUARDAR_TIPO_LICITACION",
    ACTUALIZAR_TIPO_LICITACION: "ACTUALIZAR_TIPO_LICITACION",
    ELIMINAR_TIPO_LICITACION: "ELIMINAR_TIPO_LICITACION",
    NUEVO_TIPO_LICITACION: "NUEVO_TIPO_LICITACION",
    SET_TIPO_LICITACION: "SET_TIPO_LICITACION"
};

const INITIAL_STATE = {
    listaTipoLicitacion: [],
    tipoLicitacionSelected: null,
    cargando: false,
    errores: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ACTION_TYPES.BUSCAR_TIPO_LICITACION: {
            return {
                ...state,
                listaTipoLicitacion: action.payload,
                cargando: true
            }
        }
        case ACTION_TYPES.BUSCAR_TIPO_LICITACION_POR_NOMBRE: {
            return {
                ...state,
                listaTipoLicitacion: action.payload,
                cargando: true
            }
        }
        case ACTION_TYPES.BUSCAR_TIPO_LICITACION_POR_ID: {
            return {
                ...state,
                tipoLicitacionSelected: action.payload,
                cargando: true
            }
        }
        case ACTION_TYPES.NUEVO_TIPO_LICITACION: {
            return {
                listaTipoLicitacion: [],
                tipoLicitacionSelected: null,
                errores: {},
            }
        }
        case ACTION_TYPES.GUARDAR_TIPO_LICITACION: {
            return {
                ...state,
            }
        }
        case ACTION_TYPES.ELIMINAR_TIPO_LICITACION: {
            const id = action.payload;
            return {
                ...state,
                listaTipoLicitacion: state.listaTipoLicitacion.filter((item) => item.id !== id)
            }
        }
        case ACTION_TYPES.SET_TIPO_LICITACION: {
            return {
                ...state,
                tipoLicitacionSelected: action.payload
            }
        }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/modalidad-contratacions";
const apiUrlPublic = "/services/expedientes/api/public/modalidad-contratacions";
// ACCIONES

export const buscarTipoLicitacion = () => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl);
        dispatch({
            type: ACTION_TYPES.BUSCAR_TIPO_LICITACION,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response })
    }
}
export const buscarTipoLicitacionPublic = () => async (dispatch) => {
    try {
        const res = await axios.get(apiUrlPublic);
        dispatch({
            type: ACTION_TYPES.BUSCAR_TIPO_LICITACION,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response })
    }
}

export const buscarTipoLicitacionPorNombre = (nombre) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${nombre}`)
        dispatch({
            type: ACTION_TYPES.BUSCAR_TIPO_LICITACION_POR_NOMBRE,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response })
    }
}

export const buscarTipoLicitacionPorId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/" + id)
        dispatch({
            type: ACTION_TYPES.BUSCAR_TIPO_LICITACION_POR_ID,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response })
    }
}

export const guardarTipoLicitacion = (tipoLicitacionSave) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl, tipoLicitacionSave)
        Swal.fire("", "Datos creados correctamente.", "success")
        dispatch({
            type: ACTION_TYPES.GUARDAR_TIPO_LICITACION,
            payload: res,
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response })
        throw new SubmissionError(error);
    }
}

export const actualizarTipoLicitacion = (tipoLicitacion) => async (dispatch) => {
    try {
        const res = await axios.put(apiUrl, tipoLicitacion)
        dispatch({
            type: ACTION_TYPES.ACTUALIZAR_TIPO_LICITACION,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response })
    }
}

export const eliminarTipoLicitacion = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(apiUrl + "/" + id)
        Swal.fire("", "Se eliminó correctamente.", "success")
        dispatch({
            type: ACTION_TYPES.ELIMINAR_TIPO_LICITACION,
            payload: id
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response })
        throw new SubmissionError(error);
    }
}

export const nuevoTipoLicitacion = () => async (dispatch) => {
    dispatch({ type: ACTION_TYPES.NUEVO_TIPO_LICITACION })
}

export const setTipoLicitacion = (tipoLicitacion) => async (dispatch) => {
    if (tipoLicitacion) {
        return dispatch({
            type: ACTION_TYPES.SET_TIPO_LICITACION,
            payload: tipoLicitacion,
        })
    }
}

export const setTipoLicitacionPublic = (tipoLicitacion) => async (dispatch) => {
    if (tipoLicitacion) {
        return dispatch({
            type: ACTION_TYPES.SET_TIPO_LICITACION,
            payload: tipoLicitacion,
        })
    }
}
