import axios from "axios";
import { SubmissionError } from "redux-form";
import { SET_ERROR_LIGHT, SET_ERROR, SET_WARNING_LIGHT } from "./errorReducer";

export const ACTION_TYPES = {
  FETCH_ORGANISMO_LIST: "organismo/FETCH_ORGANISMO_LIST",
  NUEVO_ORGANISMO: "organismo/NUEVO_ORGANISMO",
  GUARDAR_ORGANISMO: "organismo/GUARDAR_ORGANISMO",
  BUSCAR_ORGANISMO_POR_ID: "organismo/BUSCAR_ORGANISMO_POR_ID",
  ACTUALIZAR_ORGANISMO: "organismo/ACTUALIZAR_ORGANISMO",
  SET_ORGANISMO: "organismo/SET_ORGANISMO",
  BUSCAR_ORGANISMOS_COMBO: "BUSCAR_ORGANISMOS_COMBO",
  BUSCAR_CAUCES_POR_ORGANISMO: "organismo/BUSCAR_CAUCES_POR_ORGANISMO",
  BUSCAR_ORGANISMOS_BASE: "BUSCAR_ORGANISMOS_BASE",
  SET_ORGANISMO_BASE: "organismo/SET_ORGANISMO_BASE",
};

const initialState = {
  lstOrganismos: [],
  organismo: {},
  cargando: false,
  organismoSelected: "",
  cauces: [],
  lstOrganismoBase: [],
  organismoBase: "",
};

// Reducer

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ORGANISMO_LIST: {
      return {
        ...state,
        entities: action.payload.data,
      };
    }
    case ACTION_TYPES.BUSCAR_ORGANISMO_POR_ID: {
      return {
        ...state,
        organismo: action.payload,
      };
    }
    case ACTION_TYPES.SET_ORGANISMO: {
      return {
        ...state,
        organismo: action.payload.organismo,
        organismoSelected: action.payload,
      };
    }

    case ACTION_TYPES.SET_ORGANISMO_BASE: {
      return {
        ...state,
        organismoBase: action.payload,
      };
    }

    case ACTION_TYPES.BUSCAR_ORGANISMOS_COMBO: {
      return {
        ...state,
        lstOrganismos: action.payload,
        cargando: true,
      };
    }

    case ACTION_TYPES.BUSCAR_ORGANISMOS_BASE: {
      return {
        ...state,
        lstOrganismoBase: action.payload,
        cargando: true,
      };
    }
    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/organismos";

// Actions

export const buscarOrganismos = () => async (dispatch) => {

  var res = await axios.get(`${apiUrl}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("react-token")}`,
    },
  });
  try {
    if (res) {
      dispatch({ type: ACTION_TYPES.FETCH_ORGANISMO_LIST, payload: res }); //, payload: res });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const buscarOrganismosCombo = () => async (dispatch) => {
  const res = await axios.get(`${apiUrl}/getAllPageOrganismo`);

  try {
    if (res && res.status === 200) {
      dispatch({
        type: ACTION_TYPES.BUSCAR_ORGANISMOS_COMBO,
        payload: res.data,
      });
    }
  } catch (error) {
      
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const nuevoOrganismo = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.NUEVO_ORGANISMO });
};

export const guardarOrganismo = (organismo) => async (dispatch) => {
  try {
    var res = await axios.post("                    ", organismo);
    dispatch({ type: ACTION_TYPES.GUARDAR_ORGANISMO, payload: res });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};

export const buscarOrganismoPorCodigo = (codigo) => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/findByCodigo/${codigo}`);
    dispatch({
      type: ACTION_TYPES.BUSCAR_ORGANISMO_POR_ID,
      payload: res.data,
    });
  } catch (error) {
    dispatch({ type: SET_WARNING_LIGHT, payload: error.response });
    dispatch({ type: ACTION_TYPES.BUSCAR_ORGANISMO_POR_ID, payload: null });
  }
};

export const actualizarOrganismo = (organismo) => async (dispatch) => {
  try {
    const res = await axios.put(`       ${organismo.id}`, organismo);
    return dispatch({
      type: ACTION_TYPES.ACTUALIZAR_ORGANISMO,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};

export const setOrganismo = (organismo) => async (dispatch) => {
  if (organismo) {
    return dispatch({
      type: ACTION_TYPES.SET_ORGANISMO,
      payload: organismo,
    });
  }
};

export const setOrganismoBase = (organismo) => async (dispatch) => {
  if (organismo) {
    return dispatch({
      type: ACTION_TYPES.SET_ORGANISMO_BASE,
      payload: organismo,
    });
  }
};

export const eliminarOrganismo = (id) => async (dispatch) => {
  try {
    let res = await axios.delete(`         ${id}`);

    if (res.status === 204) {
      res.id = id;
    }

    return dispatch({
      type: ACTION_TYPES.ELIMINAR_ORGANISMO,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const buscarOrganismoPorNombre = (query) => async (dispatch) => {
  var isnum = /^\d+$/.test(query);
  let res = null;
  if (isnum) {
    res = await axios.get(`${apiUrl}/findByCodigo/${query}`);
    dispatch({
      type: ACTION_TYPES.BUSCAR_ORGANISMOS_COMBO,
      payload: [res.data],
    });
  } else {
    if (query.length > 2) {
      res = await axios.get(`${apiUrl}/findOrganismoByNombreContains/{nombre}?nombre=${query}`);
      dispatch({
        type: ACTION_TYPES.BUSCAR_ORGANISMOS_COMBO,
        payload: res.data,
      });
    }
  }
};

export const buscarOrganismosDesdeOrganismoBase = (organismo) => async (dispatch) => {
  const res = await axios.get(`${apiUrl}/listArbolDesdeOrganismoParaAbajo/${organismo}`);

  try {
    if (res && res.status === 200) {
      dispatch({
        type: ACTION_TYPES.BUSCAR_ORGANISMOS_BASE,
        payload: res.data,
      });
    }
  } catch (error) {
      
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};