import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const tipoTipoNormativa = {
    BUSCAR_TIPO_NORMATIVA: 'BUSCAR_TIPO_NORMATIVA',
    BUSCAR_TIPO_NORMATIVA_POR_ID: 'BUSCAR_TIPO_NORMATIVA_POR_ID',
    GUARDAR_TIPO_NORMATIVA: "GUARDAR_TIPO_NORMATIVA",
    ACTUALIZAR_TIPO_NORMATIVA: "ACTUALIZAR_TIPO_NORMATIVA",
    ELIMINAR_TIPO_NORMATIVA: "ELIMINAR_TIPO_NORMATIVA",
    NUEVO_TIPO_NORMATIVA: "NUEVO_TIPO_NORMATIVA",
    BUSCAR_TIPO_NORMATIVA_POR_NOMBRE: "BUSCAR_TIPO_NORMATIVA_POR_NOMBRE",
    BUSCAR_TIPO_NORMATIVA_POR_CODIGO: "BUSCAR_TIPO_NORMATIVA_POR_CODIGO",
    BUSCAR_TIPO_NORMATIVA_COMBO: "BUSCAR_TIPO_NORMATIVA_COMBO",
    SET_TIPO_NORMATIVA: "SET_TIPO_NORMATIVA"
};

const INITIAL_STATE = {
    listaTipoNormativa: [],
    tipoNormativaSelected: null,
    cargando: false,
    errores: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA:
            return {
                ...state,
                listaTipoNormativa: action.payload,
                cargando: true
            }
        case tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA_POR_ID:
            return {
                ...state,
                tipoNormativaSelected: action.payload,
                cargando: true
            }
        case tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA_POR_NOMBRE:
            return {
                ...state,
                listaTipoNormativa: action.payload,
                cargando: true
            }
        case tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA_POR_CODIGO:
            return {
                ...state,
                listaTipoNormativa: action.payload,
                cargando: true
            }
        case tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA_COMBO:
            return {
                ...state,
                listaTipoNormativa: action.payload,
                cargando: true
            }
        case tipoTipoNormativa.NUEVO_TIPO_NORMATIVA:
            return {
                listaTipoNormativa: [],
                tipoNormativaSelected: null,
                errores: {},
            }
        case tipoTipoNormativa.ELIMINAR_TIPO_NORMATIVA: {
            const id = action.payload.id;
            return {
                ...state,
                listaTipoNormativa: state.listaTipoNormativa.filter(
                    (item) => item.id !== id
                ),
            };
        }
        case tipoTipoNormativa.GUARDAR_TIPO_NORMATIVA: {
            return {
                ...state,
            };
        }
        case tipoTipoNormativa.SET_TIPO_NORMATIVA: {
            return {
                ...state,
                tipoNormativaSelected: action.payload,
            };
        }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/public/tipo-normativas";

export const buscarTipoNormativa = () => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl);
        dispatch({
            type: tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarTipoNormativaPorId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/" + id);
        dispatch({
            type: tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA_POR_ID,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const guardarTipoNormativa = (tipoNormativa) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl, tipoNormativa);
        Swal.fire("", "Datos creados correctamente.", "success");
        dispatch({
            type: tipoTipoNormativa.GUARDAR_TIPO_NORMATIVA,
            payload: res,
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
};

export const actualizarTipoNormativa = (tipoNormativa) => async (dispatch) => {
    try {
        const res = await axios.put(apiUrl, tipoNormativa);
        dispatch({
            type: tipoTipoNormativa.ACTUALIZAR_TIPO_NORMATIVA,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const eliminarTipoNormativa = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(apiUrl + "/" + id);
        Swal.fire("", "Se eliminó correctamente.", "success");
        if (res.status === 204) {
            res.id = id;
        }
        dispatch({
            type: tipoTipoNormativa.ELIMINAR_TIPO_NORMATIVA,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarTipoNormativaPorCodigo = (codigo) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByCodigo/${codigo}`);
        dispatch({
            type: tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA_POR_CODIGO,
            payload: res.data ? [res.data] : []
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarTipoNormativaPorNombre = (nombre) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${nombre}`);
        dispatch({
            type: tipoTipoNormativa.BUSCAR_TIPO_NORMATIVA_POR_NOMBRE,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarTipoNormativaCombo = (query) => async () => {
    if (query.length <= 3) {
        buscarTipoNormativaPorCodigo(query);
    } else {
        buscarTipoNormativaPorNombre(query);
    }
  };

export const nuevoTipoNormativa = () => async (dispatch) => {
    dispatch({type: tipoTipoNormativa.NUEVO_TIPO_NORMATIVA})
};

export const setTipoNormativa = (tipoNormativa) => async (dispatch) => {
    if (tipoNormativa) {
      return dispatch({
        type: tipoTipoNormativa.SET_TIPO_NORMATIVA,
        payload: tipoNormativa,
      });
    }
  };

