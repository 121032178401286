import axios from "axios";
import { SET_ERROR } from "./errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const EstadoExpediente = {
    BUSCAR_ESTADO_EXPEDIENTE: 'BUSCAR_ESTADO_EXPEDIENTE',
    SET_ESTADO_EXPEDIENTE: 'SET_ESTADO_EXPEDIENTE',
    BUSCAR_ESTADO_EXPEDIENTE_COMBO: `BUSCAR_ESTADO_EXPEDIENTE_COMBO`
};


const INITIAL_STATE = {
    EstadoExpediente: {},
    EstadoExpedienteSelected: "",
    listaEstadoExpediente: []
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case EstadoExpediente.BUSCAR_ESTADO_EXPEDIENTE: {
            return {
                ...state,
                listaEstadoExpediente: action.payload,
                cargando: true
            };
        }

        case EstadoExpediente.SET_ESTADO_EXPEDIENTE: {
            return {
                ...state,
                EstadoExpediente: action.payload.organismo,
                EstadoExpediente: action.payload,
            };
        }

        case EstadoExpediente.BUSCAR_ESTADO_EXPEDIENTE_COMBO: {
            return {
                ...state,
                listaEstadoExpediente: action.payload,
                cargando: true,
            };
        }

        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/estado-expedientes";

const apiPublicUrl = "/services/expedientes/api/public/estado-expedientes";

export const buscarEstadoExpedientesPorNombre = (query) => async (dispatch) => {
    var isnum = /^\d+$/.test(query);
    let res = null;
    if (isnum) {
        res = await axios.get(`${apiUrl}/findByCodigo/${query}`);
        dispatch({
            type: EstadoExpediente.BUSCAR_ESTADO_EXPEDIENTE_COMBO,
            payload: [res.data],
        });
    } else {
        if (query.length > 2) {
            res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${query}`);
            dispatch({
                type: EstadoExpediente.BUSCAR_ESTADO_EXPEDIENTE_COMBO,
                payload: res.data,
            });
        }
    }
};

export const buscarEstadoExpedientes = () => async (dispatch) => {
    try {

        const res = await axios.get(apiPublicUrl+"/all");

        return dispatch({
            type: EstadoExpediente.BUSCAR_ESTADO_EXPEDIENTE,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const setEstadoExpediente = (Expediente) => async (dispatch) => {
    if (Expediente) {
        return dispatch({
            type: EstadoExpediente.SET_ESTADO_EXPEDIENTE,
            payload: Expediente,
        });
    }
};

