import axios from 'axios';
import Swal from 'sweetalert2';
import { SubmissionError } from 'redux-form';
import { SET_ERROR_LIGHT, SET_ERROR } from '../errorReducer';
import { objToQuery } from "../../util/lib";

export const ACTION_TYPES = {
  BUSCAR_EXPEDIENTE: 'BUSCAR_EXPEDIENTE',
  BUSCAR_EXPEDIENTE_POR_INICIADOR: 'BUSCAR_EXPEDIENTE_POR_INICIADOR',
  BUSCAR_EXPEDIENTE_POR_ID: 'BUSCAR_EXPEDIENTE_POR_ID',
  BUSCAR_EXPEDIENTE_POR_NUMERO: 'BUSCAR_EXPEDIENTE_POR_NUMERO',
  BUSCAR_EXPEDIENTE_POR_ANIO: 'BUSCAR_EXPEDIENTE_POR_ANIO',
  BUSCAR_EXPEDIENTE_POR_ESTADO_EXP_ID: 'BUSCAR_EXPEDIENTE_POR_ESTADO_EXP_ID',
  BUSCAR_EXPEDIENTE_POR_TEMA_ID: 'BUSCAR_EXPEDIENTE_POR_TEMA_ID',
  SET_EXPEDIENTE: 'SET_EXPEDIENTE',
  BUSCAR_EXPEDIENTE_POR_ASUNTO: "BUSCAR_EXPEDIENTE_POR_ASUNTO",
  NUEVO_EXPEDIENTE: 'NUEVO_EXPEDIENTE',
  ACTUALIZAR_EXPEDIENTE: 'ACTUALIZAR_EXPEDIENTE'

};



const INITIAL_STATE = {
  listaExpediente: [],
  expediente: {  
    anio:  new Date().getFullYear(),
  },
  cargando: false,
  errores: {},
  expedienteSelected: ""
};


//Reducer 

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTION_TYPES.BUSCAR_EXPEDIENTE: {

      return {
        ...state,
        ...action.payload,
        cargando: true
      };
    }

    case ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_ID: {

      return {
        ...state,
        expediente: action.payload.data,
        cargando: true
      };
    }

    case ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_NUMERO: {
      return {
        ...state,
        listaExpediente: action.payload,
        cargando: true
      };
    }

    case ACTION_TYPES.NUEVO_EXPEDIENTE: {
      return {
        ...state,
        expediente: {
          anio:  '',
        },
      };
    }

    case ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_INICIADOR: {
      return {
        ...state,
        listaExpediente: action.payload,
        cargando: true
      };
    }

    case ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_ESTADO_EXP_ID: {
      return {
        ...state,
        listaExpediente: action.payload,
        cargando: true
      };
    }

    case ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_TEMA_ID: {
      return {
        ...state,
        listaExpediente: action.payload,
        cargando: true
      };
    }

    case ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_ASUNTO: {
      return {
        ...state,
        listaExpediente: action.payload,
        cargando: true
      }
    }

    case ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_ANIO: {

      return {
        ...state,
        expediente: action.payload.data,
        cargando: true
      };
    }

    case ACTION_TYPES.SET_EXPEDIENTE: {

      return {
        ...state,
        expediente: action.payload.expediente,
        expedienteSelected: action.payload,
      };
    }

    case ACTION_TYPES.ACTUALIZAR_EXPEDIENTE: {
      return {
        ...state,
      }
    }

    default:
      return state;
  }
}

const apiUrl = "/services/expedientes/api/expedientes";

const apiPublicUrl = "/services/expedientes/api/public/expedientes";


//Acciones

export const nuevoExpediente = () => async (dispatch) => {
  dispatch({ type: ACTION_TYPES.NUEVO_EXPEDIENTE });
};

export const buscarExpediente = (page, size, search) => async dispatch => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
      sort: "numeroGde,desc",
      ...search,
    });
  const res = await axios.get(apiUrl+"/allPerforacion" + obj);
  console.log("res reducer", res)
  let cantTotal = parseInt(res.headers["x-total-count"]);
    if (res && res.status === 200) {
      dispatch({
        type: ACTION_TYPES.BUSCAR_EXPEDIENTE, 
        payload: {
          listaExpediente: res.data,
          currentPage: page,
          totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
        }
      });
    }
  } catch (error) {
    console.log(error)
    dispatch({ type: SET_ERROR, payload: error.response });
  }

};
export const buscarExpedientePublic = (page, size, search) => async (dispatch) => {
  try {
    const obj = objToQuery({
            page: page,
            size: size,
            sort: "numeroGde,desc",
            ...search,
          });
      const res = await axios.get(apiPublicUrl+"/allPerforacion" + obj);
      dispatch({
          type: ACTION_TYPES.BUSCAR_TIPO_LICITACION,
          payload: res.data
      })
  } catch (error) {
      dispatch({ type: SET_ERROR, payload: error.response })
  }
}
// export const buscarExpedientePublic = (page, size, search) => async dispatch => {
//   try {
//     const obj = objToQuery({
//       page: page,
//       size: size,
//       sort: "numeroGde,desc",
//       ...search,
//     });
//   const res = await axios.get(apiPublicUrl+"/allPerforacion" + obj);
//   console.log("res reducer", res)
//   let cantTotal = parseInt(res.headers["x-total-count"]);
//     if (res && res.status === 200) {
//       dispatch({
//         type: ACTION_TYPES.BUSCAR_EXPEDIENTE, 
//         payload: {
//           listaExpediente: res.data,
//           currentPage: page,
//           totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
//         }
//       });
//     }
//   } catch (error) {
//     console.log(error)
//     dispatch({ type: SET_ERROR, payload: error.response });
//   }

// };


export const setExpediente = expediente => async dispatch => {
  if (expediente) {
    return dispatch({
      type: ACTION_TYPES.SET_EXPEDIENTE,
      payload: expediente
    });
  }

};

export const buscarDesdeSelect = query => async dispatch => {
  var isnum = /^\d+$/.test(query);

  if (isnum) {
    const res = await axios.get(`${apiUrl}/findByNumero/${query}`);
    dispatch({
      type: ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_NUMERO,
      payload: res.data
    });
  } else {
    try {
      const res = await axios.get(`${apiUrl}/findByAsuntoContainingIgnoreCase/${query}`)
      dispatch({
        type: ACTION_TYPES.BUSCAR_EXPEDIENTE_POR_ASUNTO,
        payload: res.data
      })
    } catch (error) {
      dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
      throw new SubmissionError(error);
    }
  }
}
