import axios from "axios";
import { SET_ERROR, SET_ERROR_LIGHT } from "../errorReducer";
import { SubmissionError } from "redux-form";
import Swal from "sweetalert2";

export const tipoOrgano = {
    BUSCAR_TIPO_ORGANOS: "BUSCAR_TIPO_ORGANOS",
    BUSCAR_TIPO_ORGANOS_POR_ID: "BUSCAR_TIPO_ORGANOS_POR_ID",
    BUSCAR_TIPO_ORGANOS_POR_CODIGO: "BUSCAR_TIPO_ORGANOS_POR_CODIGO",
    BUSCAR_TIPO_ORGANOS_POR_NOMBRE: "BUSCAR_TIPO_ORGANOS_POR_NOMBRE",
    ACTUALIZAR_TIPO_ORGANOS: "ACTUALIZAR_TIPO_ORGANOS",
    GUARDAR_TIPO_ORGANOS: "GUARDAR_TIPO_ORGANOS",
    ELIMINAR_TIPO_ORGANOS: "ELIMINAR_TIPO_ORGANOS",
    NUEVO_TIPO_ORGANOS: "NUEVO_TIPO_ORGANOS"
}

const INITIAL_STATE = {
    listaTipoOrgano: [],
    tipoOrganoSelected: null,
    cargando: false,
    errores: {}
}

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {
        case tipoOrgano.BUSCAR_TIPO_ORGANOS: {
            return {
                ...state,
                listaTipoOrgano: action.payload,
                cargando: true
            };
        }
        case tipoOrgano.BUSCAR_TIPO_ORGANOS_POR_ID: {
            return {
                ...state,
                tipoOrganoSelected: action.payload,
                cargando: true
            };
        }
        case tipoOrgano.ELIMINAR_TIPO_ORGANOS: {
            const id = action.payload.id;
            return {
                ...state,
                listaTipoOrgano: state.listaTipoOrgano.filter(
                    (item) => item.id !== id
                ),
            };
        }
        case tipoOrgano.BUSCAR_TIPO_ORGANOS_POR_CODIGO: {
            return {
                ...state,
                listaTipoOrgano: action.payload,
                cargando: true
            }
        }
        case tipoOrgano.BUSCAR_TIPO_ORGANOS_POR_NOMBRE: {
            return {
                ...state,
                listaTipoOrgano: action.payload,
                cargando: true
            }
        }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/public/organos";

export const buscarTipoOrgano = () => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl);
        dispatch({
            type: tipoOrgano.BUSCAR_TIPO_ORGANOS,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const buscarTipoOrganoPorId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/" + id)
        dispatch({
            type: tipoOrgano.BUSCAR_TIPO_ORGANOS_POR_ID,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const actualizarTipoOrgano = (tipoOrgano) => async (dispatch) => {
    try {
        const res = await axios.put(apiUrl, tipoOrgano);
        Swal.fire("", "Se Actualizo correctamente.", "success");
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const guardarTipoOrgano = (tipoOrgano) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl, tipoOrgano);
        Swal.fire("", "Se Guardo correctamente.", "success");
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const eliminarTipoOrgano = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(apiUrl + "/" + id);
        Swal.fire("", "Se eliminó correctamente.", "success");
        if (res.status === 204) {
            res.id = id;
        }
        return dispatch({
            type: tipoOrgano.ELIMINAR_TIPO_ORGANOS,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const nuevoTipoOrgano = () => async (dispatch) => {
    dispatch({ type: tipoOrgano.NUEVO_TIPO_ORGANOS });
};

export const buscarTipoOrganoPorCodigo = (codigo) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByCodigo/${codigo}`)
        dispatch({
            type: tipoOrgano.BUSCAR_TIPO_ORGANOS_POR_CODIGO,
            payload: res.data ? [res.data] : [] 
        })
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const buscarTipoOrganoPorNombre = (nombre) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${nombre}`)
        dispatch({
            type: tipoOrgano.BUSCAR_TIPO_ORGANOS_POR_NOMBRE,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
        throw new SubmissionError(error);
    }
}

export const buscarDesdeSelect = query => async dispatch => {
    var isnum = /^\d+$/.test(query);
    let res = null;
    if (isnum) {
        buscarTipoOrganoPorCodigo(isnum);
    } else {
        buscarTipoOrganoPorNombre(query)
    }
}