import { combineReducers } from "redux";

import { reducer as reduxForm } from "redux-form";
import organismosReducers from "./organismosReducers";
import prorrataReducers from "./prorrataReducers";
import OrganismoReducer from "./OrganismoReducer"

import errorReducer from "./errorReducer";
import servicioReducer from "./servicioReducer";
import mapaHidricoReducer from "./mapaHidricoReducer";
import tipoOrganoReducer from "./digesto/tipoOrganoReducer";

import personaReducer from "./personaReducer";
import expedienteReducer from "./gDoc/expedienteReducer";
import transaccionesReducer from "./recaudacion/transaccionesReducer";
import boletoReducer from "./recaudacion/boletoReducer";
import estadoTransaccionReducer from "./recaudacion/estadoTransaccionReducer";
import solicitudTransferenciaReducer from "./solicitudTransferenciaReducer";
import extractoReducer from "./recaudacion/extractoReducer";
import archivosExtractoReducer from "./recaudacion/archivosExtractoReducer";

import tipoNormativaReducer from './digesto/tipoNormativaReducer';
import estadoNormativaReducer from './digesto/estadoNormativaReducer';
import areaNormativaReducer from './digesto/areaNormativaReducer';
import lugarPublicacionReducer from './digesto/lugarPublicacionReducer';
import tipoTransaccionReducer from "./recaudacion/tipoTransaccionReducer";
import normativaReducer from './digesto/normativaReducer';
import archivoNormativaReducer from './digesto/archivoNormativaReducer';
import EstadoExpedienteReducer from './EstadoExpedienteReducer';
import TemaExpedienteReducer from './TemaExpedienteReducer';
import reparticionReducer from './gDoc/reparticionReducer';
import tipoLicitacionReducer from './licitacion/tipoLicitacionReducer';
import licitacionReducer from './licitacion/licitacionReducer';
import archivoLicitacionReducer from './licitacion/archivoLicitacionReducer';
import telemetriaMovilReducer from "./telemetriaMovi/telemetriaMovilReducer";
import distribucionReducer from "./distribucionReducer";

export default combineReducers({
  form: reduxForm,
  organismosReducers,
  errorReducer,
  prorrataReducers,
  servicioReducer,
  mapaHidricoReducer,
  tipoOrganoReducer,
  personaReducer,
  expedienteReducer,
  transaccionesReducer,
  boletoReducer,
  estadoTransaccionReducer,
  extractoReducer,
  archivosExtractoReducer,
  solicitudTransferenciaReducer,
  tipoNormativaReducer,
  estadoNormativaReducer,
  areaNormativaReducer,
  lugarPublicacionReducer,
  tipoTransaccionReducer,
  normativaReducer,
  archivoNormativaReducer,
  OrganismoReducer,
  EstadoExpedienteReducer,
  TemaExpedienteReducer,
  reparticionReducer,
  licitacionReducer,
  tipoLicitacionReducer,
  archivoLicitacionReducer,
  telemetriaMovilReducer,
  distribucionReducer
});
