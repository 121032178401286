import axios from "axios";
import {
  SET_ERROR
} from "../errorReducer";
import { objToQuery } from "../../util/lib";

export const ACTION_TYPES = {
  BUSCAR_DATOS_ALL: 'BUSCAR_DATOS_ALL',

};

const INITIAL_STATE = {
  listaDatos: [],
  errores: {},
  cargando: false,

};

//reducer

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case ACTION_TYPES.BUSCAR_DATOS_ALL: {
      return {
        ...state,
        ...action.payload,
        cargando: true,
      };

    }
    default:
      return state;
  }
};

const apiUrl = '/services/telemetria/api/public/dato-medicions/fullDto';

export const buscarDatoMedicion = (search) => async (dispatch) => {

  const obj = objToQuery({
    ...search,
   
  });

  try {
    
    const res = await axios.get(apiUrl + obj);
    res.data = res.data.sort((a, b) => {
      if (!(a.codigoMaestro[0]*1+1)){
        return 1;//
      }
      else if (!(b.codigoMaestro[0]*1+1)){
        return -1;
      }
      // const diff = new Date(b.fecha) - new Date(a.fecha)
      // if (diff!==0)return diff;
      return -(b.codigoMaestro.split("-")[0]-a.codigoMaestro.split("-")[0])
    });
    // for (const row of res.data){
    //   row.fechaFormateada=new Date(row.fecha).toLocaleString();
    // }
    dispatch({
      type: ACTION_TYPES.BUSCAR_DATOS_ALL,
      payload: {
        listaDatos: res.data,
      },
    });
  } catch (error) {

    dispatch({ type: SET_ERROR, payload: error.response });
    throw error;
  }
};
