import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { SubmissionError } from "redux-form";

export const tipoEstadoNormativa = {
    BUSCAR_ESTADO_NORMATIVA: 'BUSCAR_ESTADO_NORMATIVA',
    BUSCAR_ESTADO_NORMATIVA_POR_ID: 'BUSCAR_ESTADO_NORMATIVA_POR_ID',
    GUARDAR_ESTADO_NORMATIVA: "GUARDAR_ESTADO_NORMATIVA",
    ACTUALIZAR_ESTADO_NORMATIVA: "ACTUALIZAR_ESTADO_NORMATIVA",
    ELIMINAR_ESTADO_NORMATIVA: "ELIMINAR_ESTADO_NORMATIVA",
    NUEVO_ESTADO_NORMATIVA: "NUEVO_ESTADO_NORMATIVA",
    BUSCAR_ESTADO_NORMATIVA_POR_NOMBRE: "BUSCAR_ESTADO_NORMATIVA_POR_NOMBRE",
    BUSCAR_ESTADO_NORMATIVA_POR_CODIGO: "BUSCAR_ESTADO_NORMATIVA_POR_CODIGO",
    BUSCAR_ESTADO_NORMATIVA_COMBO: "BUSCAR_ESTADO_NORMATIVA_COMBO",
    SET_ESTADO_NORMATIVA: "SET_ESTADO_NORMATIVA"
};

const INITIAL_STATE = {
    listaEstadoNormativa: [],
    estadoNormativaSelected: null,
    cargando: false,
    errores: {},
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA:
            return {
                ...state,
                listaEstadoNormativa: action.payload,
                cargando: true
            }
        case tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA_POR_ID:
            return {
                ...state,
                estadoNormativaSelected: action.payload,
                cargando: true
            }
        case tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA_POR_NOMBRE:
            return {
                ...state,
                listaEstadoNormativa: action.payload,
                cargando: true
            }
        case tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA_POR_CODIGO:
            return {
                ...state,
                listaEstadoNormativa: action.payload,
                cargando: true
            }
        case tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA_COMBO:
            return {
                ...state,
                listaEstadoNormativa: action.payload,
                cargando: true
            }
        case tipoEstadoNormativa.NUEVO_ESTADO_NORMATIVA:
            return {
                listaEstadoNormativa: [],
                estadoNormativaSelected: null,
                errores: {},
            }
        case tipoEstadoNormativa.ELIMINAR_ESTADO_NORMATIVA: {
            const id = action.payload.id;
            return {
                ...state,
                listaEstadoNormativa: state.listaEstadoNormativa.filter(
                    (item) => item.id !== id
                ),
            };
        }
        case tipoEstadoNormativa.GUARDAR_ESTADO_NORMATIVA: {
            return {
                ...state,
            };
        }
        case tipoEstadoNormativa.SET_ESTADO_NORMATIVA: {
            return {
                ...state,
                estadoNormativaSelected: action.payload,
            };
        }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/public/estado-normativas";

export const buscarEstadoNormativa = () => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl);
        dispatch({
            type: tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarEstadoNormativaPorId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/" + id);
        dispatch({
            type: tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA_POR_ID,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const guardarEstadoNormativa = (estadoNormativa) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl, estadoNormativa);
        Swal.fire("", "Datos creados correctamente.", "success");
        dispatch({
            type: tipoEstadoNormativa.GUARDAR_ESTADO_NORMATIVA,
            payload: res,
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
};

export const actualizarEstadoNormativa = (estadoNormativa) => async (dispatch) => {
    try {
        const res = await axios.put(apiUrl, estadoNormativa);
        dispatch({
            type: tipoEstadoNormativa.ACTUALIZAR_ESTADO_NORMATIVA,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const eliminarEstadoNormativa = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(apiUrl + "/" + id);
        Swal.fire("", "Se eliminó correctamente.", "success");
        if (res.status === 204) {
            res.id = id;
        }
        dispatch({
            type: tipoEstadoNormativa.ELIMINAR_ESTADO_NORMATIVA,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarEstadoNormativaPorCodigo = (codigo) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByCodigo/${codigo}`);
        dispatch({
            type: tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA_POR_CODIGO,
            payload: res.data ? [res.data] : []
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarEstadoNormativaPorNombre = (nombre) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${nombre}`);
        dispatch({
            type: tipoEstadoNormativa.BUSCAR_ESTADO_NORMATIVA_POR_NOMBRE,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarEstadoNormativaCombo = (query) => async () => {
    if (query.length <= 3) {
        buscarEstadoNormativaPorCodigo(query);
    } else {
        buscarEstadoNormativaPorNombre(query);
    }
  };

export const nuevoEstadoNormativa = () => async (dispatch) => {
    dispatch({type: tipoEstadoNormativa.NUEVO_ESTADO_NORMATIVA})
};

export const setEstadoNormativa = (estadoNormativa) => async (dispatch) => {
    if (estadoNormativa) {
      return dispatch({
        type: tipoEstadoNormativa.SET_ESTADO_NORMATIVA,
        payload: estadoNormativa,
      });
    }
  };

