import axios from "axios";
import { SET_ERROR } from "../errorReducer";
import Swal from "sweetalert2";
import { objToQuery } from "../../util/lib";
import { SubmissionError } from "redux-form";

export const tipoAreaNormativa = {
    BUSCAR_AREA_NORMATIVA: 'BUSCAR_AREA_NORMATIVA',
    BUSCAR_AREA_NORMATIVA_POR_ID: 'BUSCAR_AREA_NORMATIVA_POR_ID',
    GUARDAR_AREA_NORMATIVA: "GUARDAR_AREA_NORMATIVA",
    ACTUALIZAR_AREA_NORMATIVA: "ACTUALIZAR_AREA_NORMATIVA",
    ELIMINAR_AREA_NORMATIVA: "ELIMINAR_AREA_NORMATIVA",
    NUEVA_AREA_NORMATIVA: "NUEVA_AREA_NORMATIVA",
    BUSCAR_AREA_NORMATIVA_POR_NOMBRE: "BUSCAR_AREA_NORMATIVA_POR_NOMBRE",
    BUSCAR_AREA_NORMATIVA_POR_CODIGO: "BUSCAR_AREA_NORMATIVA_POR_CODIGO",
    BUSCAR_AREA_NORMATIVA_COMBO: "BUSCAR_AREA_NORMATIVA_COMBO",
    SET_AREA_NORMATIVA: "SET_AREA_NORMATIVA",
    SET_DETALLE_AREA_NORMATIVA: "SET_DETALLE_AREA_NORMATIVA",
    BORRAR_DETALLE_AREA_NORMATIVA: "BORRAR_DETALLE_AREA_NORMATIVA",
};

const INITIAL_STATE = {
    listaAreaNormativa: [],
    listaAreaNormativaSelected: [],
    areaNormativaSelected: null,
    cargando: false,
    errores: {},
    currentPage: 0,
    totalPages: 0,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case tipoAreaNormativa.BUSCAR_AREA_NORMATIVA:
            return {
                ...state,
                ...action.payload,
                cargando: true
            }
        case tipoAreaNormativa.BUSCAR_AREA_NORMATIVA_POR_ID:
            return {
                ...state,
                areaNormativaSelected: action.payload,
                cargando: true
            }
        case tipoAreaNormativa.BUSCAR_AREA_NORMATIVA_POR_NOMBRE:
            return {
                ...state,
                ...action.payload,
                cargando: true
            }
        case tipoAreaNormativa.BUSCAR_AREA_NORMATIVA_POR_CODIGO:
            return {
                ...state,
                listaAreaNormativa: action.payload,
                currentPage: 0,
                totalPages: 1,
                cargando: true
            }
        case tipoAreaNormativa.BUSCAR_AREA_NORMATIVA_COMBO:
            return {
                ...state,
                listaAreaNormativa: action.payload,
                cargando: true
            }
        case tipoAreaNormativa.NUEVA_AREA_NORMATIVA:
            return {
                listaAreaNormativa: [],
                areaNormativaSelected: null,
                errores: {},
                currentPage: 0,
                totalPages: 0,
            }
        case tipoAreaNormativa.ELIMINAR_AREA_NORMATIVA: {
            const id = action.payload.id;
            return {
                ...state,
                listaAreaNormativa: state.listaAreaNormativa.filter(
                    (item) => item.id !== id
                ),
            };
        }
        case tipoAreaNormativa.GUARDAR_AREA_NORMATIVA: {
            return {
                ...state,
            };
        }
        case tipoAreaNormativa.SET_AREA_NORMATIVA: {
            return {
                ...state,
                areaNormativaSelected: action.payload,
            };
        }
        case tipoAreaNormativa.SET_DETALLE_AREA_NORMATIVA: {
            const dato = action.payload
            return {
                ...state,
                listaAreaNormativaSelected: [...state.listaAreaNormativaSelected, dato],
                cargando: true
            };
        }
        case tipoAreaNormativa.BORRAR_DETALLE_AREA_NORMATIVA: {
            return {
                ...state,
                listaAreaNormativaSelected: state.listaAreaNormativaSelected.filter(
                    (item) => item.id !== action.payload
                ),
            };
        }
        default:
            return state;
    }
}

const apiUrl = "/services/expedientes/api/public/area-normativas";

export const buscarAreaNormativa = (page, size) => async (dispatch) => {
    const obj = objToQuery({
        page,
        size
    });
    try {
        const res = await axios.get(apiUrl + obj);
        let cantTotal = parseInt(res.headers["x-total-count"]);
        dispatch({
            type: tipoAreaNormativa.BUSCAR_AREA_NORMATIVA,
            payload: {
                listaAreaNormativa: res.data,
                currentPage: page,
                totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
            },
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarAreaNormativaPorId = (id) => async (dispatch) => {
    try {
        const res = await axios.get(apiUrl + "/" + id);
        dispatch({
            type: tipoAreaNormativa.BUSCAR_AREA_NORMATIVA_POR_ID,
            payload: res.data
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const guardarAreaNormativa = (areaNormativa) => async (dispatch) => {
    try {
        const res = await axios.post(apiUrl, areaNormativa);
        Swal.fire("", "Datos creados correctamente.", "success");
        dispatch({
            type: tipoAreaNormativa.GUARDAR_AREA_NORMATIVA,
            payload: res,
        });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
        throw new SubmissionError(error);
    }
};

export const actualizarAreaNormativa = (areaNormativa) => async (dispatch) => {
    try {
        const res = await axios.put(apiUrl, areaNormativa);
        dispatch({
            type: tipoAreaNormativa.ACTUALIZAR_AREA_NORMATIVA,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const eliminarAreaNormativa = (id) => async (dispatch) => {
    try {
        const res = await axios.delete(apiUrl + "/" + id);
        Swal.fire("", "Se eliminó correctamente.", "success");
        if (res.status === 204) {
            res.id = id;
        }
        dispatch({
            type: tipoAreaNormativa.ELIMINAR_AREA_NORMATIVA,
            payload: res
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarAreaNormativaPorNombre = (nombre, page, size) => async (dispatch) => {
    const obj = objToQuery({
        page,
        size
    });
    try {
        const res = await axios.get(`${apiUrl}/findByNombreContainingIgnoreCase/${nombre}${obj}`);
        let cantTotal = parseInt(res.headers["x-total-count"]);
        dispatch({
            type: tipoAreaNormativa.BUSCAR_AREA_NORMATIVA_POR_NOMBRE,
            payload: {
                listaAreaNormativa: res.data,
                currentPage: page,
                totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
            },
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarAreaNormativaPorCodigo = (codigo) => async (dispatch) => {
    try {
        const res = await axios.get(`${apiUrl}/findByCodigo/${codigo}`);
        dispatch({
            type: tipoAreaNormativa.BUSCAR_AREA_NORMATIVA_POR_CODIGO,
            payload: res.data ? [res.data] : [],
        })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const buscarAreaNormativaCombo = (query) => async () => {
    if (query.length <= 3) {
        buscarAreaNormativaPorCodigo(query)
    } else {
        buscarAreaNormativaPorNombre(query)
    }
};

export const nuevaAreaNormativa = () => async (dispatch) => {
    dispatch({type: tipoAreaNormativa.NUEVA_AREA_NORMATIVA})
};

export const setAreaNormativa = (areaNormativa) => async (dispatch) => {
    if (areaNormativa) {
      return dispatch({
        type: tipoAreaNormativa.SET_AREA_NORMATIVA,
        payload: areaNormativa,
      });
    }
  };

export const setDetalleAreaNormativa = (areaNormativa) => async (dispatch) => {
    if (areaNormativa) {
        return dispatch({
            type: tipoAreaNormativa.SET_DETALLE_AREA_NORMATIVA,
            payload: areaNormativa,
        });
    }
}

export const borrarDetalleAreaNormativa = (id) => async (dispatch) => {
    dispatch({
        type: tipoAreaNormativa.BORRAR_DETALLE_AREA_NORMATIVA,
        payload: id,
    });
}

