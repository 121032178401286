import axios from "axios";
import {
  SET_ERROR,
  // SET_ERROR_LIGHT,
  // SET_WARNING_LIGHT /* , SET_WARNING */,
} from "../errorReducer";
// import { SubmissionError } from "redux-form";
import Swal from "sweetalert2";
// import { objToQuery } from "../../util/lib";

export const tiposBoletos = {
  BUSCAR_BOLETO_POR_NUMERO: "BUSCAR_BOLETO_POR_NUMERO",
  BUSCAR_LISTADO_BOLETO: "BUSCAR_LISTADO_BOLETO",
  SET_BOLETO: "SET_BOLETO"
};

const INITIAL_STATE = {
  listaBoletos: [],
  boleto: null
};

//Reducer

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tiposBoletos.BUSCAR_BOLETO_POR_NUMERO: {
      return {
        ...state,
        boleto: action.payload.data,
        cargando: true,
      };
    }
    case tiposBoletos.SET_BOLETO: {
      return {
        ...state,
        boleto: action.payload,
        cargando: true,
      };
    }    
    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/public/boletos/";

//Acciones

export const buscarBoletoPorAnioNumero = (anio, numero) => async (dispatch) => {
  try{
    
  const res = await axios.get(`${apiUrl}findByAnioAndNumero/${anio}-${numero}`);
  
    if(res && res.status === 200){
      dispatch({ type: tiposBoletos.BUSCAR_BOLETO_POR_NUMERO, payload: res });
    }else if (res && res.status === 404){
      Swal.fire({
        title: "No se encontró el Boleto",
        icon: "warning",
        showCancelButton: false,
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Aceptar",
    });
    }
  } catch (error) {
      
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const setBoleto = (boleto) => (dispatch) => {
  dispatch({ type: tiposBoletos.SET_BOLETO, payload: boleto });
};

export const buscarBoletoPorAnioNumeroNoPagado = (anio, numero) => async (dispatch) => {
  try{
  const res = await axios.get(`${apiUrl}AnioAndNumeroNoPagado/${anio}-${numero}`);
  
    if(res && res.status === 200){
      dispatch({ type: tiposBoletos.BUSCAR_BOLETO_POR_NUMERO, payload: res });
    }else if (res && res.status === 404){
      Swal.fire({
        title: "No se encontró el Boleto",
        icon: "warning",
        showCancelButton: false,
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Aceptar",
    });
    }
  } catch (error) {
      
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};