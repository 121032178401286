import axios from "axios";
import { SET_ERROR, SET_ERROR_LIGHT } from "../errorReducer";
// import { SubmissionError } from "redux-form";
import Swal from "sweetalert2";
// import { objToQuery } from "../../util/lib";

export const tipoExtracto = {
  BUSCAR_LISTADO_ARCHIVOS_EXTRACTO: "BUSCAR_LISTADO_ARCHIVOS_EXTRACTO",
  CARGAR_ARCHIVO_EXTRACTO: "CARGAR_ARCHIVO_EXTRACTO",
};

const INITIAL_STATE = {
  listaArchivosExtracto: [],
  errores: {},
  cargando: false,
  cantidad: 0,
  currentPage: 0,
  totalPages: 0,
};

//Reducer

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tipoExtracto.BUSCAR_LISTADO_ARCHIVOS_EXTRACTO: {
      return {
        ...state,
        ...action.payload,
        cargando: true,
      };
    }
    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/archivos";

//Acciones

export const buscarArchivosExtracto = (page, size, search) => async (
  dispatch
) => {
  try {
    let res = await axios.get(`${apiUrl}`);
    if (res && res.status === 200) {
      dispatch({
        type: tipoExtracto.BUSCAR_LISTADO_ARCHIVOS_EXTRACTO,
        payload: {
          listaArchivosExtracto: res.data,
        },
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const cargarArchivoExtracto = (archivo) => async (dispatch) => {
  try {
    const res = await axios
      .post("/services/presupuesto/api/uploadFile", archivo, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {},
      })
      .then((res) => {
        Swal.fire("", res.data, "success");
      });

    dispatch({
      type: tipoExtracto.CARGAR_ARCHIVO_EXTRACTO,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR, payload: error.response });
  }
};

export const descargarArchivo = (codigo) => async (dispatch) => {
  let extension = codigo.split(".").pop();
  try {
    // axios(apiUrl + "/descargar?archivo=" + codigo, {
    // method: 'GET',
    // responseType: 'blob' //Force to receive data in a Blob Format
    // })

    //   .then(response => {//Create a Blob from the PDF Stream
    //     const file = new Blob(
    //     [response.data],
    //     {type: 'application/'+ extension});//Build a URL from the file
    //     if (extension==='pdf'){
    //       const file = new Blob(
    //       [response.data],
    //       {type: 'application/'+ extension});//Build a URL from the file
    //       const fileURL = URL.createObjectURL(file);//Open the URL on new Window
    //       window.open(fileURL);
    //     /* pdfWindow.location.href = fileURL;*/
    //     }
    // });
    const res = await axios(apiUrl + "/descargar?archivo=" + codigo, {
      responseType: "blob",
    });
    const file = new Blob([res.data], { type: "application/" + extension });
    const resp = {
      extension: extension,
      file: file,
    };
    return resp;
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};
