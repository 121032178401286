import axios from 'axios';
import { SET_ERROR, SET_ERROR_LIGHT } from './errorReducer';

export const tiposDistribucion = {
    BUSCAR_DISTRIBUCION: 'BUSCAR_DISTRIBUCION',

};

const INITIAL_STATE = {
    listaDistribucion: [],
    cargando: false,
    errores: {},
    distribucionSelected: null,

};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case tiposDistribucion.BUSCAR_DISTRIBUCION: {
            return {
                ...state,
                listaDistribucion: action.payload,
                cargando: true
            };
        }
        case tiposDistribucion.SET_DISTRIBUCION: {
            return {
                ...state,
                distribucionSelected: action.payload,
            };
        }

        default:
            return state;
    }
}

const apiUrl = '/services/cdt/api/public/distribucions';


export const buscarDistribucion = () => async dispatch => {
    try {
        let res = null;
        res = await axios.get(apiUrl + '/findByCuencasPrincipal');
        const newlist = [];
        for (const dist of res.data) {
            if (![4635, 4637].includes(dist.id)) {
                newlist.push(dist);
            }
        }
        res.data = newlist;

        dispatch({ type: tiposDistribucion.BUSCAR_DISTRIBUCION, payload: res.data });
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.response });
    }
};

export const setDistribucion = (distribucion) => async (dispatch) => {
    if (distribucion) {
        return dispatch({
            type: tiposDistribucion.SET_DISTRIBUCION,
            payload: distribucion,
        });
    }
};

export const buscarDistribucionCombo = (query) => async (dispatch) => {
    //no hago nada
};