import axios from "axios";
import {
  // SET_ERROR,
  SET_ERROR_LIGHT,
} from "../errorReducer";
// import { SubmissionError } from "redux-form";
// import Swal from "sweetalert2";
import { objToQuery } from "../../util/lib";

export const tipoExtracto = {
  BUSCAR_LISTADO_EXTRACTO: "BUSCAR_LISTADO_EXTRACTO",
  ENVIAR_TRANSFERENCIAS: 'ENVIAR_TRANSFERENCIAS',
  SET_RESULTADO_PROCESAR_EXTRACTO: 'SET_RESULTADO_PROCESAR_EXTRACTO',
};

const INITIAL_STATE = {
  listaExtracto: [],
  movimiento: null,
  errores: {},
  cargando: false,
  cantidad: 0,
  currentPage: 0,
  totalPages: 0,
  resProcesar: null,
};

//Reducer

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case tipoExtracto.BUSCAR_LISTADO_EXTRACTO: {
      return {
        ...state,
        ...action.payload,
        cargando: true,
      };
    }
    case tipoExtracto.ENVIAR_TRANSFERENCIAS: {
      return {
        ...state,
        resProcesar: action.payload,
        cargando: true,
      };
    }
    case tipoExtracto.SET_RESULTADO_PROCESAR_EXTRACTO: {
      return {
        ...state,
        resProcesar: action.payload,
      };
    }       
    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api/extracto-transaccions";

//Acciones

export const buscarExtracto = (page, size) => async (dispatch) => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
    });
    let res = await axios.get(`${apiUrl}${obj}`);
    let cantTotal = parseInt(res.headers["x-total-count"]);
    if (res && res.status === 200) {
      dispatch({
        type: tipoExtracto.BUSCAR_LISTADO_EXTRACTO,
        payload: {
          listaExtracto: res.data,
          currentPage: page,
          totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
        },
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const buscarExtractoConFiltro = (page, size, search) => async (dispatch) => {
  try {
    const obj = objToQuery({
      page: page,
      size: size,
      ...search,
    });
    const res = await axios.get(apiUrl + obj +"&sort=id,desc");
    let cantTotal = parseInt(res.headers["x-total-count"]);
    if (res && res.status === 200) {
      dispatch({
        type: tipoExtracto.BUSCAR_LISTADO_EXTRACTO,
        payload: {
          listaExtracto: res.data,
          currentPage: page,
          totalPages: parseInt(cantTotal / size) + (cantTotal % size ? 1 : 0),
        },
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const enviarTransferencias = () => async (dispatch) => {
  try {
    const res = await axios.post(apiUrl + '/procesar');
    if (res && res.status === 200) {
      dispatch({
        type: tipoExtracto.ENVIAR_TRANSFERENCIAS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });    
  }
}

export const setResultadoProcesarExtracto = (resProcesar) => (dispatch) => {
  try {
      dispatch({
        type: tipoExtracto.SET_RESULTADO_PROCESAR_EXTRACTO,
        payload: resProcesar,
      });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });    
  }
}