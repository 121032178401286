import axios from "axios";
import { SubmissionError } from "redux-form";
import { SET_ERROR_LIGHT } from "./errorReducer";
import Swal from "sweetalert2";

export const ACTION_TYPES = {
  FETCH_SERVICIO_LIST: "servicio/FETCH_SERVICIO_LIST",
  BUSCAR_SERVICIO_POR_CODIGO: "servicio/BUSCAR_SERVICIO_POR_CODIGO",
  SET_SERVICIO: "servicio/SET_SERVICIO",
  INICIALIZAR: "servicio/INICIALIZAR",
  INICIALIZAR_DETALLE_SERVICIO: "INICIALIZAR_DETALLE_SERVICIO",
  BUSCAR_SERVICIOS_POR_USUARIO: "servicio/BUSCAR_SERVICIOS_POR_USUARIO",
  BUSCAR_SERVICIO_POR_NC: "servicio/BUSCAR_SERVICIO_POR_NC",
  BUSCAR_SERVICIO_POR_ID: "servicio/BUSCAR_SERVICIO_POR_ID",
};

const initialState = {
  lstServicios: [],
  servicio: null,
  errores: {},
};

// Reducer

export default function (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SERVICIO_LIST: {
      return {
        ...state,
        lstServicios: action.payload.data,
      };
    }

    case ACTION_TYPES.BUSCAR_SERVICIO_POR_CODIGO: {
      return {
        ...state,
        servicio: action.payload.data,
      };
    }

    case ACTION_TYPES.SET_SERVICIO: {
      return {
        ...state,
        servicio: action.payload,
      };
    }

    case ACTION_TYPES.INICIALIZAR: {
      return {
        ...state,
        lstServicios: [],
        servicio: null,
      };
    }

    case ACTION_TYPES.INICIALIZAR_DETALLE_SERVICIO: {
      return {
        ...state,
        lstServicios: [],
      };
    }

    case ACTION_TYPES.BUSCAR_SERVICIOS_POR_USUARIO: {
      return {
        ...state,
        lstServicios: action.payload.data,
      };
    }

    case ACTION_TYPES.BUSCAR_SERVICIO_POR_NC: {
      return {
        ...state,
        servicio: action.payload.data,
      };
    }
    case ACTION_TYPES.BUSCAR_SERVICIO_POR_ID: {
      return {
        ...state,
        servicio: action.payload.data,
      };
    }
    default:
      return state;
  }
}

const apiUrl = "/services/presupuesto/api";

// Actions

export const buscarServicios = () => async (dispatch) => {
  var res = await axios.get(`${apiUrl}/servicios`);

  if (res) {
    console.info(res);
    dispatch({ type: ACTION_TYPES.FETCH_SERVICIO_LIST, payload: res }); //, payload: res });
  } else {
    //manejar error
  }
};

export const buscarServicioPorCodigo = (servicio) => async (dispatch) => {
  try {
    const tipo = servicio.tipoServicio.codigo;
    let codigo = tipo;
    if (tipo === "A") {
      codigo =
        codigo + "" + servicio.codigo1 + "" + servicio.codigo2.padStart(4, "0");
    } else if (tipo === "B") {
      codigo =
        codigo +
        "" +
        servicio.codigo1.padStart(2, "0") +
        "" +
        servicio.codigo2.padStart(6, "0");
    } else if (tipo === "C") {
      codigo = codigo + "" + servicio.codigo1.padStart(9, "0");
    }

    const res = await axios.get(
      `${apiUrl}/servicios/getServicioByCodigo/${codigo}`
    );
    if (res && res.status === 200) {
      dispatch({ type: ACTION_TYPES.BUSCAR_SERVICIO_POR_CODIGO, payload: res });
      return res.data;
    } else {
      Swal.fire("error", "No se encontraron datos", "warning");
    }
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};

export const buscarFacturas = (servicio, cuota1) => async (dispatch) => {
  try {

    const cuota = {
      id: cuota1.id
    }
    const servicioCuota = { servicio, cuota };
    
    let res = await axios.post(
      `${apiUrl}/recaudacion/facturacion`,
      servicioCuota
    );
    dispatch({ type: ACTION_TYPES.FETCH_SERVICIO_LIST, payload: res });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
  }
};

export const setServicio = (servicio) => async (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.SET_SERVICIO,
    payload: servicio,
  });
};

export const inicializarServicio = () => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.INICIALIZAR,
    payload: null,
  });
};

export const inicializarDetalleServicio = () => (dispatch) => {
  return dispatch({
    type: ACTION_TYPES.INICIALIZAR_DETALLE_SERVICIO,
    payload: null,
  });
};

export const buscarServiciosPorUsuario = (persona) => async (dispatch) => {
  try {
    const res = await axios.post(
      `${apiUrl}/servicios/getServicioByPersona`,
      persona
    );
    dispatch({
      type: ACTION_TYPES.BUSCAR_SERVICIOS_POR_USUARIO,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};
export const buscarServicioPorNc = (nc) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${apiUrl}/servicios/getServicioByNomenclatura/${nc}`
    );

    dispatch({
      type: ACTION_TYPES.BUSCAR_SERVICIO_POR_NC,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    // dispatch({type: tipoCategoriaDerecho.BUSCAR_CATEGORIA_DERECHO_POR_CODIGO, payload: null});
  }
};

export const buscarServicioPorId = (id) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl + "/servicios/" + id);
    dispatch({
      type: ACTION_TYPES.BUSCAR_SERVICIO_POR_ID,
      payload: res,
    });
  } catch (error) {
    dispatch({ type: SET_ERROR_LIGHT, payload: error.response });
    throw new SubmissionError(error);
  }
};
